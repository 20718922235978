import React from "react";

import {
  Link,
  NavLink,
  Route,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components";

import dashboardLogo from "../../assets/dashboardLogo.svg";
import exploreIconInactive from "../../assets/explore-inactive.svg";
import exploreIconActive from "../../assets/explore.svg";
import dataIconActive from "../../assets/data-active.svg";
import dataIconInactive from "../../assets/data-inactive.svg";

// import { themeContext, ThemeProvider } from './lib/themeContext';

import { DashboardLayout } from "../../components/Layout";
import ExplorePage from "./Explore";
// import EntriesPage from "./EntriesPage";
import DataPage from "./Data";
// import ResultPage from "./Results";
import SpecificResultPage from "./SpecificResult";
import SourceDetailsPage from "./SourceDetails";
import DataSearchPage from "./DataSearchPage";
import ViewData from "./ViewData";
import APIKeysPage from "./Settings/apiKeys";
import ProfileUpdatePage from "./Settings/profileUpdate";
import { CustomSwitch, RedirectToLostPage } from "../../shared/routing";
import PlanPage from "./Plan";

const DashboardAside = styled.aside`
  background: #442ecf;
  left: 0;
  top: 0;
`;

const NavLi = styled(NavLink)`
  & > div {
    border-radius: 10px;
    background: transparent;
  }

  &.active > div,
  &:hover > div {
    background: #ffffff08;
  }
`;

const DashboardHome = (props) => {
  let { path, url } = useRouteMatch();
  let location = useLocation();

  return (
    <DashboardLayout className="grid min-h-screen">
      <DashboardAside className="px-3 sticky top-0">
        <div className="sticky top-0 py-6 ">
          <Link to="/" className="flex">
            <img
              src={dashboardLogo}
              alt="SocialSafety"
              className="mb-20 mx-auto"
            />
          </Link>
          <ul className="">
            <NavLi
              to={`/dashboard/explore`}
              isActive={(match, location) => {
                if (match || location.pathname === `${url}`) {
                  return true;
                } else {
                  return false;
                }
              }}
              activeClassName="active"
              title="Explore"
            >
              <div className="p-4 mx-auto mb-4">
                <img
                  src={
                    location.pathname.includes(`dashboard/explore`) ||
                    location.pathname === "/dashboard"
                      ? exploreIconActive
                      : exploreIconInactive
                  }
                  alt="Explore"
                  className="w-6 h-6"
                />
              </div>
            </NavLi>
            <NavLi title="Data" to={`/dashboard/data`} activeClassName="active">
              <div className="p-4">
                <img
                  src={
                    location.pathname.includes(`/dashboard/data`)
                      ? dataIconActive
                      : dataIconInactive
                  }
                  alt="Data"
                />
              </div>
            </NavLi>
          </ul>
        </div>
      </DashboardAside>
      <CustomSwitch>
        <Route path="/dashboard/explore/view-data" component={ViewData} />

        <Route path="/dashboard/data" component={DataPage} />

        <Route path="/dashboard/plan" component={PlanPage} />

        <Route path="/dashboard/apikeys" component={APIKeysPage} />

        <Route path="/dashboard/profile" component={ProfileUpdatePage} />

        <Route
          path="/dashboard/explore/source/details/:query"
          component={SourceDetailsPage}
        />

        <Route
          path="/dashboard/explore/result/:query"
          component={SpecificResultPage}
        />

        <Route path="/dashboard/explore/:query" component={DataSearchPage} />

        <Route path={path} component={ExplorePage} />

        <RedirectToLostPage />
      </CustomSwitch>
    </DashboardLayout>
  );
};

export default DashboardHome;
