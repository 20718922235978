import React, { useContext } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";
import { NavLink, Link } from "react-router-dom";

import { DrawerContext } from "../lib/drawerContext";
// import { authContext } from "../store/AuthStore";
import logoDark from "../assets/darkLogo.svg";
// import Footer from "./Footers";

const DrawerStyle = styled.aside`
  position: fixed;
  top: 0;
  right: -110%;
  transition: right 0.3s ease-in-out;
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  background: #ffffff;

  &.active {
    right: 0;
  }
`;

const ExpandedSideNav = styled.ul`
  background: #ffffff;
  width: 100%;
  z-index: 100003;
  position: relative;
`;

const ExpandedNavLink = styled(NavLink)`
  margin-bottom: 8px;
  padding: 9px 16px;
  border-radius: 4px;
  color: #646a86;
  font-family: "medium", sans-serif;
  font-size: 16px;

  &.active {
    font-weight: 600;
    background: #442ed0;
    color: #ffffff;
  }
`;

const Drawer = () => {
  const { toggleDrawer, isDrawerActive } = useContext(DrawerContext);
  // let { path } = useRouteMatch();
  // let history = useHistory();

  // const { auth } = useContext(authContext);

  // const onLogOut = () => {
  //   auth.updateAuth({
  //     loggedIn: false,
  //   });
  //   history.push("/");
  // };

  return (
    <DrawerStyle className={`${isDrawerActive ? "active" : ""}`}>
      <div
        className="absolute w-full h-full"
        style={{ zIndex: 100001 }}
        onClick={toggleDrawer}
      ></div>
      <ExpandedSideNav className="h-full px-2 pb-8 flex flex-col justify-between ">
        <div className="sticky top-0 bg-white pt-6 flex justify-between items-center mb-4 px-2">
          <Link to="/" onClick={toggleDrawer}>
            <img src={logoDark} alt="" />
          </Link>
          <Icon.X
            color="#767E85"
            size={48}
            onClick={toggleDrawer}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-between flex-grow">
          {/* Top Section */}
          <div className="mb-96 px-2">
            <ExpandedNavLink
              to={`/organizations`}
              className="flex items-center"
              onClick={toggleDrawer}
              activeClassName="active"
            >
              <li className="flex-grow">SNN for Organizations</li>
            </ExpandedNavLink>

            <ExpandedNavLink
              to={`/initiatives`}
              className="flex items-center"
              activeClassName="active"
              onClick={toggleDrawer}
            >
              <li className="flex-grow">Initiatives</li>
            </ExpandedNavLink>

            <ExpandedNavLink
              to={`/datalabs`}
              className="flex items-center"
              activeClassName="active"
              onClick={toggleDrawer}
            >
              <li className="flex-grow">Data Labs</li>
            </ExpandedNavLink>
          </div>
          {/* <div className="-mx-4">
            <Footer />
          </div> */}
        </div>
      </ExpandedSideNav>
    </DrawerStyle>
  );
};

export default Drawer;
