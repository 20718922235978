import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import curveArrowIcon from "../../assets/curve arrow.svg";
import searchSmallIcon from "../../assets/search-small.svg";

import { P, H5 } from "../../components/Texts";
import { DarkFormField } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import { DashboardHeader } from "../../components/Headers";
import { authContext } from "../../store/AuthStore";
import { DataResultCard } from "../../components/Cards";
import { toastWarning } from "../../lib/utils";

const FETCH_DATA_BY_USER = gql`
  query getData($userId: ID!) {
    getDataByUserID(userId: $userId) {
      data {
        id
        name
        title
        description
        topic
        access
        country
        version
        author
        createdAt
        updatedAt
        updatedLastBy
        attribution
        attribution_url
        resources {
          format
          name
          encoding
          hash
          path
          pathType
        }
      }
    }
  }
`;

const EntriesPage = (props) => {
  let history = useHistory();

  const { auth } = useContext(authContext);

  const checkVerified = () => toastWarning("Please verify your email first");

  const [getData, { loading, data }] = useLazyQuery(FETCH_DATA_BY_USER);

  useEffect(() => {
    if (auth?.user?.id) {
      getData({
        variables: { userId: auth.user.id },
      });
    }
  }, [auth, getData]);

  const searchEntry = (data) => {};

  const createEntry = () => {
    history.push("/dashboard/data/new-entry");
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section>
      <DashboardHeader title="Data" className="sticky top-0" />
      <section className="px-8 py-6">
        <div className="flex justify-between items-center">
          <div className="w-full md:w-1/5">
            <form onSubmit={handleSubmit(searchEntry)}>
              <DarkFormField
                type="search"
                id="entry"
                name="entry"
                className="py-1"
                ref={register({
                  required: "Please enter a need",
                })}
                placeholder="Search"
                iconBefore={searchSmallIcon}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />
              <ErrorMessage
                errors={errors}
                name="need"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </form>
          </div>

          {auth?.user?.isVerified ? (
            <PrimaryButton className="" onClick={createEntry}>
              Upload new data
            </PrimaryButton>
          ) : (
            <PrimaryButton className="" onClick={checkVerified}>
              Upload new data
            </PrimaryButton>
          )}
        </div>
        {loading ? (
          <div className="mt-10 grid md:grid-cols-3 grid-flow-row gap-10 mb-6">
            {Array(6)
              .fill("")
              .map((_, idx) => (
                <div className="" key={idx}>
                  <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
                  <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                  <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                  <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
                  <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
                </div>
              ))}
          </div>
        ) : (
          <>
            {data?.getDataByUserID?.data.length > 0 ? (
              <>
                <div className="grid md:grid-cols-3 grid-flow-row gap-6 mb-6">
                  {data?.getDataByUserID?.data.map((result, idx) => (
                    <Link
                      key={idx}
                      to={`/dashboard/explore/source/details/${result.id}`}
                    >
                      <DataResultCard
                        key={idx}
                        type={"business"}
                        {...result}
                        // entry={entry}
                      />
                    </Link>
                  ))}
                </div>
              </>
            ) : (
              <div className="mt-40">
                <img src={curveArrowIcon} alt="" className="mx-auto mb-10" />
                <H5
                  color="#0D024E"
                  fontSize="24px"
                  className="text-center mb-6"
                >
                  You don't have any data yet
                </H5>
                <P color="#9490B1" fontSize="16px" className="text-center">
                  Trying using the blue button to create a new data
                </P>
              </div>
            )}
          </>
        )}
      </section>
    </section>
  );
};

export default EntriesPage;
