import React, { useState } from "react";
import styled from "styled-components";

export const HeaderButton = styled.button`
  background: ${(props) => props.bg || "#442ECF"};
  color: ${(props) => props.color || "#F5F4FA"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-family: ${(props) => props.fontFamily || '"semi", sans-serif'};
  padding: ${(props) => props.p || "10px 24px"};
  width: ${(props) => props.w};
  border-radius: 3px;

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
  }
`;

export const PrimaryButton = styled.button`
  background: ${(props) => props.bg || "#442ECF"};
  color: ${(props) => props.color || "#F5F4FA"};
  color: ${(props) => props.disabled && "#B4B0D1"};
  background: ${(props) => props.disabled && "#F5F4FA"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-family: ${(props) => props.fontFamily || "semi"}, sans-serif;
  padding: ${(props) => props.p || "14px 24px"};
  width: ${(props) => props.w};
  transition: 0.3s ease;
  border-radius: 3px;

  &:hover {
    color: ${(props) => props.hoverColor};
    background: ${(props) => props.hoverBg};
    background: ${(props) => props.disabled && "#F5F4FA"};
    cursor: ${(props) => props.disabled && "not-allowed"};
  }

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
  }
`;

PrimaryButton.White = styled.button`
  width: ${(props) => props.w};
  background: ${(props) => props.bg || "#ffffff"};
  color: ${(props) => props.color || "#442ED0"};
  color: ${(props) => props.disabled && "#B4B0D1"};
  background: ${(props) => props.disabled && "#F5F4FA"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-family: ${(props) => props.fontFamily || "semi"}, sans-serif;
  padding: ${(props) => props.p || "14px 24px"};
  transition: 0.3s ease;
  border-radius: 3px;

  &:hover {
    background: ${(props) => props.hoverBg || "#eeeeee"};
    background: ${(props) => props.disabled && "#F5F4FA"};
    cursor: ${(props) => props.disabled && "not-allowed"};
  }

  &:focus {
    border-color: #ffffff50;
    outline-color: #ffffff50;
  }
`;

export const OutlineButton = styled.button`
  background: ${(props) => props.bg || "#ffffff"};
  color: ${(props) => props.color || "#442ED0"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-family: ${(props) => props.fontFamily || "semi"}, sans-serif;
  padding: ${(props) => props.p || "10px 20px"};
  color: ${(props) => props.disabled && "#B4B0D1"};
  background: ${(props) => props.disabled && "#F5F4FA"};
  width: ${(props) => props.w};
  border: 1px solid #cecae1;
  border-radius: 3px;

  &:hover {
    color: ${(props) => props.hoverColor};
    background: ${(props) => props.hoverBg};
    background: ${(props) => props.disabled && "#F5F4FA"};
    cursor: ${(props) => props.disabled && "not-allowed"};
  }

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
  }
`;

export const LinkButton = styled.button`
  background: ${(props) => props.bg || "#transparent"};
  color: ${(props) => props.color || "#442ECF"};
  color: ${(props) => props.disabled && "#B4B0D1"};
  font-size: ${(props) => props.fontSize || "14px"};
  font-family: ${(props) => props.fontFamily || "semi"}, sans-serif;
  padding: ${(props) => props.p || "10px 0"};
  width: ${(props) => props.w};
  // border: 1px solid #CECAE1;
  // border-radius: 3px;

  &:hover {
    color: ${(props) => props.hoverColor};
    color: ${(props) => props.disabled && "#B4B0D1"};
    cursor: ${(props) => props.disabled && "not-allowed"};
  }

  &:focus {
    // border-color: #442ECF50;
    outline-color: #442ecf50;
  }

  @media (max-width: 600px) {
    font-size: ${(props) => props.smallSize || "36px"};
  }
`;

export const GrayButton = styled.button`
  padding: 8px 18px;
  background: ${(props) => (props.bg ? props.bg : "#DADEE2")};
  color: #393c40;
  font-weight: 600;
  border-radius: 3px;

  &:hover {
    background: ${(props) => props.hoverBg};
  }
`;

export const TabButton = styled(PrimaryButton)`
  padding: ${(props) => props.p || "16px"};
  background: ${(props) => props.bg || "#E7EAF4"};
  color: ${(props) => props.color || "#878B9A"};
  border: ${(props) => props.borderless || "1px solid #E7EAF4"};
  box-shadow: 0px 1px 4px #3f3f4419;

  &.active {
    background: #33333390;
    color: #ffffff;
  }

  &:hover {
    background: #c9cdd1;
    color: #878b9a;
  }

  &:focus {
    background: #33333390;
    color: #ffffff;
  }
`;

export const PlainButton = styled.button`
  width: ${(props) => props.w};
  border: none;

  &:disabled {
    filter: grayscale(1);

    &:hover {
      cursor: not-allowed;
    }
  }
`;

const ToggleStyle = styled.div`
  height: 25px;
  width: 43px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;

  background: ${(props) =>
    props.active ? props.activeBg || "#442ED0" : props.inactiveBg || "#c4c0ba"};

  & > .knob {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    position: relative;
    left: ${(props) => (props.active ? "20px" : "3px")};
    transition: 0.3s ease-in;
  }
`;

export const Toggle = (props) => {
  const [active, setActive] = useState(false);

  const toggleState = () => setActive(!active);

  return (
    <ToggleStyle
      onClick={toggleState}
      active={active}
      className="cursor-pointer"
    >
      <div className="knob"></div>
    </ToggleStyle>
  );
};
