import React, { useRef } from "react";
import { Link } from "react-feather";
import { useForm } from "react-hook-form";
import LoadingBar from "react-top-loading-bar";
import { ErrorMessage } from "@hookform/error-message";

import check from "../assets/check.svg";
import dashboardLogo from "../assets/dashboardLogo.svg";

import { P, H6, H5, H4 } from "../components/Texts";
import { DarkFormField } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import { ThemeLessLayout } from "../components/Layout";
import FormCard from "../components/FormCard";

const checklist = [
  {
    heading: "Search for data",
    body: "Find the latest data on a variety of topics from A to Z.",
  },
  {
    heading: "Transform data",
    body: "Change data formats and clean data faster than ever.",
  },
  {
    heading: "Visualize",
    body: "Use built-in visualization tools to create shareable custom dashboards or export data to your preferred software.",
  },
];

const CheckItem = ({ heading, body }) => (
  <div className="flex mb-6">
    <img src={check} alt="" className="h-6 w-6 mr-4" />
    <div>
      <H5 color="#ffffff" fontSize="18px">
        {heading}
      </H5>
      <P color="#B4B0D1" className="mt-2" fontSize="14px">
        {body}
      </P>
    </div>
  </div>
);

const ForgotPassword = (props) => {
  const onSubmit = (data) => {};

  const loader = useRef(null);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <ThemeLessLayout>
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="grid grid-cols-2 items-center">
        <div className="mx-auto w-3/5">
          <Link to="/" className="flex">
            <img src={dashboardLogo} alt="SocialSafety" className="mb-12" />
            <H4 className="pt-0.7 pl-1" color="#ffffff">
              DataLabs
            </H4>
          </Link>
          <div className="mt-20">
            {checklist.map((item, idx) => (
              <CheckItem key={idx} heading={item.heading} body={item.body} />
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <FormCard
            className="px-4 md:px-10 py-8 md:py-16 mx-auto w-3/4"
            maxW="476px"
          >
            <H6 color="#22202D" fontSize="18px" className="mb-12">
              Reset your password to continue sharing, accessing, and working
              with your data.
            </H6>

            <form onSubmit={handleSubmit(onSubmit)}>
              <DarkFormField
                type="email"
                id="email"
                name="email"
                w="135px"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email.",
                  },
                })}
                placeholder="example@mail.com"
                label="Email"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <PrimaryButton className="w-full">Reset Password</PrimaryButton>
            </form>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default ForgotPassword;
