import React from "react";
import { Link } from "react-router-dom";
import t from "prop-types";

import { H4, P } from "./Texts";
import logo from "../assets/logo.svg";

const FooterSection = ({ heading, subLinks, isDark }) => (
  <div className="mr-16">
    <H4 color="#333" className="mb-6">
      {heading}
    </H4>
    {subLinks.map((link, idx) => (
      <React.Fragment key={idx}>
        {link.external ? (
          <a
            key={idx}
            href={link.to}
            target="_blank"
            rel="noreferrer noopener"
            className="block mb-4"
          >
            <P fontSize="16px" color="#828282">
              {link.name}
            </P>
          </a>
        ) : (
          <Link key={idx} to={link.to} className="block mb-4">
            <P fontSize="16px" color="#828282">
              {link.name}
            </P>
          </Link>
        )}
      </React.Fragment>
    ))}
  </div>
);

FooterSection.propTypes = {
  heading: t.string.isRequired,
  subLinks: t.array,
};

const Footer = (isDark) => (
  <footer className="container">
    <div className="space-y-10 md:space-y-0 md:flex flex justify-between">
      {/* LOGO */}
      <div>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      {/* Product */}
      <div>
        <div className="flex">
          {/* <FooterSection
            heading="Documentation"
            subLinks={[
              {
                name: "Developers",
                to: "/developers",
              },
              {
                name: "Using Datalabs",
                to: "/usingdatalabs",
              },
            ]}
          /> */}
          <FooterSection
            heading="Company"
            subLinks={[
              {
                name: "About us",
                to: "/about",
              },
              {
                name: "Contact Us",
                to: "/contact",
              },
              {
                name: "Philanthrolab.co",
                to: "https://philanthrolab.co",
                external: true,
              },
              {
                name: "Pricing and Plans",
                to: "/pricing",
              },
            ]}
          />
          <FooterSection
            heading="Legal"
            subLinks={[
              {
                name: "Terms of Service",
                to: "/terms",
              },
              {
                name: "Privacy Policy",
                to: "/privacy",
              },
            ]}
          />
          {/* <FooterSection
            heading="Community"
            subLinks={[
              {
                name: "Blog",
                to: "/blog",
                external: true,
              },
              {
                name: "Newsletter",
                to: "/newsletter",
                external: true,
              },
              {
                name: "Twitter",
                to: "https://twitter.com",
                external: true,
              },
              {
                name: "LinkedIn",
                to: "https://linkedin.com",
                external: true,
              },
              {
                name: "GitHub",
                to: "https://github.com",
                external: true,
              },
              {
                name: "YouTube",
                to: "https://youtube.com",
                external: true,
              },
              {
                name: "Slack",
                to: "https://slack.com",
                external: true,
              },
            ]}
          /> */}
        </div>

        <P className="mt-10 mb-6" color="#828282" fontSize="16px">
          Copyright © Philanthrolab 2021, All rights reserved.
        </P>
      </div>
    </div>
  </footer>
);

export default Footer;
