import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import Drawer from "../../components/Drawer";
import { P, H1, H4 } from "../../components/Texts";
import CtaSection from "../../components/CtaSection";
import { DataLabsHeader } from "../../components/Headers";
import Footer from "../../components/LandingPageFooter";
import { OutlineButton, PrimaryButton } from "../../components/Buttons";

import { decodeToken } from "../../lib/utils";
import { authContext } from "../../store/AuthStore";

import purpleCheckIcon from "../../assets/check-purple.svg";
import blackCheckIcon from "../../assets/check-black.svg";

// import Faq from "../../components/FaqList";

const Hero = styled.section`
  background: #ffffff;
  // height: 100vh;
  // min-height: 100vh;
  // max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const PricingTable = styled.table`
  width: 100%;

  th {
    border-right: 1px solid #cecbe2;
  }

  td {
    text-align: center;
    padding: 20px 10px;
    border-right: 1px solid #cecbe2;
  }

  td:first-child {
    text-align: left;
  }

  th:nth-child(2),
  td:nth-child(2) {
    background-color: #f5f4fa;
  }

  th:not(:first-child) {
    border-top: 1px solid #cecbe2;
  }

  .last-row td:not(:first-child) {
    border-bottom: 1px solid #cecbe2;
  }
`;

const Pricing = () => {
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  return (
    <main>
      <DataLabsHeader isValid={isValid} />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="max-h-screen w-full flex justify-center items-center lg:mt-56 lg:mb-32">
        <div className="text-center pl-4 pr-4 md:pr-0 lg:pl-24 lg:pr-4 pt-10 lg:pt-10 lg:w-1/2 md:mx-auto md:w-8/12 flex flex-col lg:mb-10">
          <H1
            color="#442ED0"
            className="mb-10 lg:w-5/6 font-bold mx-auto"
            mediumSize="56px"
            smallSize="32px"
          >
            Pricing that makes sense for everyone.
          </H1>
          <H4
            color="#0C024D"
            fontFamily="regular"
            className="md:mx-auto"
            fontSize="18px"
          >
            We enable innovators to build new products and services on our APIs,
            or to become citizen data scientist in only a few clicks.
          </H4>
        </div>
      </Hero>

      <div className="w-10/12 mx-auto mb-20">
        <PricingTable className="p-4">
          <thead>
            <tr className="">
              <th></th>
              <th className="p-4 text-left">
                <P color="#1E202A" fontSize="18px">
                  Free
                </P>
                <P color="#442ED0" fontSize="24px" className="mt-2 mb-5">
                  $0/month
                </P>
                {!isValid ? (
                  <Link to="/login">
                    <PrimaryButton className="w-full">
                      Get started
                    </PrimaryButton>
                  </Link>
                ) : (
                  <Link to="/dashboard/profile">
                    <PrimaryButton className="w-full">
                      Get started
                    </PrimaryButton>
                  </Link>
                )}
              </th>
              <th className="p-4 text-left">
                <P color="#1E202A" fontSize="18px">
                  Basic
                </P>
                <P color="#442ED0" fontSize="24px" className="mt-2 mb-5">
                  $14.99/month
                </P>
                {!isValid ? (
                  <Link to="/login">
                    <OutlineButton className="w-full">
                      Get started
                    </OutlineButton>
                  </Link>
                ) : (
                  <Link to="/dashboard/profile">
                    <OutlineButton className="w-full">
                      Get started
                    </OutlineButton>
                  </Link>
                )}
              </th>
              <th className="p-4 text-left">
                <P color="#1E202A" fontSize="18px">
                  Pro
                </P>
                <P color="#1E202A" fontSize="24px" className="mt-2 mb-5">
                  Custom
                </P>
                <Link to="/contact">
                  <OutlineButton className="w-full">
                    Contact sales
                  </OutlineButton>
                </Link>
              </th>
            </tr>
          </thead>
          <tbody className="">
            <tr>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  Daily API call Limit
                </P>
              </td>
              <td>
                <P color="#6650F0" fontSize="18px" fontFamily="medium">
                  100
                </P>
              </td>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="medium">
                  1,000
                </P>
              </td>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="medium">
                  Unlimited
                </P>
              </td>
            </tr>
            <tr>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  Support for full text search
                </P>
              </td>
              <td>
                <img src={purpleCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
            </tr>

            <tr>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  Advance filtering for search
                </P>
              </td>
              <td>
                <Icon.X
                  className="mx-auto font-extrabold"
                  size={34}
                  color="#CF2E32"
                />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
            </tr>

            <tr>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  In-browser data exploration
                </P>
              </td>
              <td>
                <img src={purpleCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
            </tr>

            <tr>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  In-browser data visualization
                </P>
              </td>
              <td>
                <img src={purpleCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
              <td>
                <img src={blackCheckIcon} alt="Yes" className="mx-auto" />
              </td>
            </tr>

            <tr className="last-row">
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="bold">
                  Support for private dataset
                </P>
              </td>
              <td>
                <Icon.X
                  className="mx-auto font-extrabold"
                  size={34}
                  color="#CF2E32"
                />
              </td>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="medium">
                  20
                </P>
              </td>
              <td>
                <P color="#1E202A" fontSize="18px" fontFamily="medium">
                  Unlimited
                </P>
              </td>
            </tr>
          </tbody>
        </PricingTable>
      </div>

      {/* Frequently Asked Questions */}
      {/* <div className="">
        <H1
          color="#442ED0"
          className="mb-10 lg:w-5/6 font-bold mx-auto text-center"
          mediumSize="56px"
          smallSize="32px"
        >
          FAQ
        </H1>
        <div className="mb-32 mx-auto">
          <Faq
            title="Can I trial datalabs before paying?"
            description="We enable innovators to build new products and services on our APIS, or to become citizen data scientist in only a few clicks."
          />
          <Faq
            title="Does Datalabs have an API?"
            description="We enable innovators to build new products and services on our APIS, or to become citizen data scientist in only a few clicks."
          />
          <Faq
            title="What kind of support does Datalabs provide?"
            description="We enable innovators to build new products and services on our APIS, or to become citizen data scientist in only a few clicks."
          />
          <Faq
            title="Can I cancel my account plan at any time?"
            description="We enable innovators to build new products and services on our APIS, or to become citizen data scientist in only a few clicks."
          />
        </div>
      </div> */}

      <CtaSection />
      <Footer />
    </main>
  );
};

export default Pricing;
