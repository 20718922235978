import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import t from "prop-types";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useForm } from "react-hook-form";

import searchIcon from "../assets/search.svg";
import paperSearchIcon from "../assets/paper-search.svg";
import importIcon from "../assets/import.svg";
import transformIcon from "../assets/transform.svg";
import analyzeIcon from "../assets/analyze.svg";
import visualizeIcon from "../assets/Initiatives.svg";
import exportIcon from "../assets/export.svg";
import testimonial_1_Img from "../assets/testimonial-1.png";
import testimonial_2_Img from "../assets/testimonial-2.png";
import testimonial_3_Img from "../assets/testimonial-3.png";

import Drawer from "../components/Drawer";
import { P, BigP, H3, SmallP, H1, H4, H2 } from "../components/Texts";
import CtaSection from "../components/CtaSection";
import { DataLabsHeader } from "../components/Headers";
import { SearchInputWidget } from "../components/Inputs";
import { useRef } from "react";
import { useHistory } from "react-router";
import Footer from "../components/LandingPageFooter";
import { authContext } from "../store/AuthStore";
import { decodeToken } from "../lib/utils";

const Hero = styled.section`
  background: #ffffff;
  // height: 100vh;
  // min-height: 100vh;
  // max-height: 100vh;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const FeatureCardStyle = styled.div`
  @media (max-width: 600px) {
    img {
      width: 54px;
      height: 56px;
    }
  }
`;

const FeatureCard = ({ icon, heading, body, link }) => (
  <FeatureCardStyle className="flex flex-col justify-between h-full">
    <div>
      <img src={icon} alt="" className="h-20 mb-8" />
      <H3 className="mb-6" color="#fff">
        {heading}
      </H3>
      <P fontSize="18px" className="mb-6" color="#CECBE2">
        {body}
      </P>
    </div>
    {/* <Link to={link}>
      <TextLink color="#fff" fontFamily="medium" className="flex items-center">
        Learn more
        <Icon.ArrowRight color="#fff" size={16} className="ml-4" />
      </TextLink>
    </Link> */}
  </FeatureCardStyle>
);

FeatureCard.propTypes = {
  icon: t.string,
  heading: t.string.isRequired,
  body: t.string.isRequired,
  link: t.string,
};

const SliderStyle = styled.section`
  max-width: 100vh;

  .slide-holder {
    min-height: 415px;
  }

  .slide-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: all 500ms ease;
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .slide-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 500ms ease;
  }
`;

const TestimonialImageStyle = styled.div`
  width: 85px;
  height: 85px;

  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid ${(props) => (props.active ? "#7D6BF1" : "transparent")};
`;

const testimonials = [
  {
    body: "“DataLabs has this astonishing ability to be welcoming to beginners, yet unrestrained for advanced users. As someone who works with data, this makes datalabs a clear choice as the industry-standard tool to find and manage your data.”",
    author: "Silvia Bormüller",
    position: "CEO Trainnex",
  },
  {
    body: "“I found DataLabs to be the bridge between good intentions and meaningful outcomes. It has helped us unlock our data potential and I can't stop recommending it to people.”",
    author: "Julia Muld",
    position: "CEO Pharmatex",
  },
  {
    body: "“I see Datalabs as a global force and standard when it comes data management tools for public good. An overall great product.”",
    author: "Kelvin Walsh",
    position: "CTO Lista",
  },
];

const Testimonial = ({ body, author, position }) => (
  <div>
    <BigP
      fontSize="40px"
      smallSize="24px"
      fontFamily="regular"
      lineHeight="48px"
      className="text-center mx-auto md:w-3/4 mb-12"
      color="#442ED0"
    >
      {body}
    </BigP>
    <P
      fontSize="24px"
      fontFamily="medium"
      className="text-center mb-4"
      color="#0C024D"
    >
      {author}
    </P>
    <SmallP color="#393648" fontSize="16px" className="text-center">
      {position}
    </SmallP>
  </div>
);

Testimonial.propTypes = {
  body: t.string.isRequired,
  author: t.string.isRequired,
  position: t.string.isRequired,
};

const Home = (props) => {
  const [testimonial, setTestimonial] = useState(0);
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(false);
  const searchInputRef = useRef(null);
  let history = useHistory();

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  const onSearch = (data) => {
    history.push(`/search/${data.query}`);
  };

  const focusSearch = (e) => {
    searchInputRef.current.focus();
  };

  const { register, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  useEffect(() => {
    if (searchInputRef.current) {
      register(searchInputRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <DataLabsHeader isValid={isValid} />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="max-h-screen w-full flex justify-center items-center lg:mt-56 lg:mb-32">
        <div className="text-center pl-4 pr-4 md:pr-0 lg:pl-24 lg:pr-4 pt-10 lg:pt-10 lg:w-1/2 md:mx-auto md:w-8/12 flex flex-col lg:mb-10">
          <H1
            color="#442ED0"
            className="mb-10 lg:w-3/4 font-bold mx-auto"
            mediumSize="56px"
            smallSize="32px"
          >
            A singular place for actionable data.
          </H1>
          <H4
            color="#0C024D"
            fontFamily="regular"
            className="mb-10 lg:w-5/6 md:mx-auto "
            fontSize="18px"
          >
            We enable innovators to build new products and services on our APIS,
            or to become citizen data scientist in only a few clicks.
          </H4>
          <form onSubmit={handleSubmit(onSearch)}>
            <SearchInputWidget
              type="search"
              id="query"
              name="query"
              iconBefore={searchIcon}
              // inputClassName="h-10"
              containerOnClick={focusSearch}
              ref={(e) => {
                searchInputRef.current = e;
                register(searchInputRef, {
                  required: "Enter a phrase.",
                });
              }}
              placeholder="Search for public data"
            />
          </form>
        </div>
      </Hero>
      {/* What is DataLabs */}
      <div style={{ backgroundColor: "#0C024D" }}>
        <section className="container mx-auto py-10 md:py-24 -mt-12 ">
          <div className="md:mr-10 md:text-center">
            <H2
              color="#ffffff"
              fontSize="50px"
              fontFamily="semi"
              lineHeight="64px"
              className="text-center md:w-4/5 mx-auto md:mb-10"
            >
              We’re in the business of finding and freeing relevant data
              necessary to power public good.
            </H2>
          </div>
        </section>
        <section
          className="container mx-auto py-10 md:pb-56 -mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 row-gap-20 col-gap-10 lg:row-gap-40"
          style={{ backgroundColor: "#0C024D" }}
        >
          <FeatureCard
            icon={paperSearchIcon}
            heading="Search for data"
            body="Find the latest data on a variety of topics from A to Z."
            link="/organizations"
          />
          <FeatureCard
            icon={importIcon}
            heading="Import data"
            body="Import data as CSV, JSON or APIs and set visibility to private or public."
            link="/initiatives"
          />
          <FeatureCard
            icon={transformIcon}
            heading="Transform data"
            body="Import data as CSV, JSON or APIs and set visibility to private or public."
            link="/datalabs"
          />
          <FeatureCard
            icon={analyzeIcon}
            heading="Analyze"
            body="Run simple or complex statistical analysis all with the click of a button."
            link="/datalabs"
          />
          <FeatureCard
            icon={visualizeIcon}
            heading="Visualize "
            body="Use Datalabs built in visualization tools to create shareable custom dashboards or export data to your preferred visualization software."
            link="/datalabs"
          />
          <FeatureCard
            icon={exportIcon}
            heading="Export"
            body="Export data in any file format of your choice."
            link="/datalabs"
          />
        </section>
      </div>
      <SliderStyle className="container overflow-x-hidden py-16 md:py-36 ">
        <div className="flex items-center justify-center mb-10 md:mb-16">
          <TestimonialImageStyle
            onClick={() => setTestimonial(0)}
            active={testimonial === 0}
            className="mr-6"
          >
            <img src={testimonial_1_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle
            onClick={() => setTestimonial(1)}
            active={testimonial === 1}
            className="mr-6"
          >
            <img src={testimonial_2_Img} alt="" className="" />
          </TestimonialImageStyle>
          <TestimonialImageStyle
            onClick={() => setTestimonial(2)}
            active={testimonial === 2}
          >
            <img src={testimonial_3_Img} alt="" className="" />
          </TestimonialImageStyle>
        </div>
        <div className="slide-holder">
          <TransitionGroup className="slide-holder">
            {testimonial === 0 && (
              <CSSTransition timeout={500} classNames="slide">
                <Testimonial
                  body={testimonials[0].body}
                  author={testimonials[0].author}
                  position={testimonials[0].position}
                />
              </CSSTransition>
            )}
            {testimonial === 1 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[1].body}
                  author={testimonials[1].author}
                  position={testimonials[1].position}
                />
              </CSSTransition>
            )}
            {testimonial === 2 && (
              <CSSTransition timeout={300} classNames="slide">
                <Testimonial
                  body={testimonials[2].body}
                  author={testimonials[2].author}
                  position={testimonials[2].position}
                />
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </SliderStyle>
      <CtaSection />
      <Footer />
    </main>
  );
};

export default Home;
