import React, { useRef } from "react";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.css";

import { PrimaryButton } from "./Buttons";

const HandsOnTable = ({ data }) => {
  const [columns, ...rest] = data;

  const hotTableComponent = useRef(null);

  const downloadFile = () => {
    // const hot = new Handsontable(hotTableComponent);

    const exportPlugin =
      hotTableComponent.current.hotInstance.getPlugin("exportFile");

    exportPlugin.downloadFile("csv", {
      bom: false,
      columnDelimiter: ",",
      columnHeaders: false,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: "csv",
      filename: "Datalabs-CSV-file_[YYYY]-[MM]-[DD]",
      mimeType: "text/csv",
      rowDelimiter: "\r\n",
      rowHeaders: true,
    });
  };

  return (
    <div>
      <HotTable
        data={rest}
        colHeaders={columns}
        rowHeaders
        // maxRows={22}
        stretchH="all"
        manualRowResize
        manualColumnResize
        manualRowMove
        manualColumnMove
        contextMenu
        filters
        dropdownMenu
        mergeCells
        exportFile
        collapsibleColumns
        hiddenColumns
        multiColumnSorting={{
          indicator: true,
        }}
        height={400}
        ref={hotTableComponent}
        licenseKey="non-commercial-and-evaluation"
      />
      <div className="my-4">
        <PrimaryButton onClick={downloadFile}>Download Table</PrimaryButton>
      </div>
    </div>
  );
};

export default HandsOnTable;
