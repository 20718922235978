/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { gql, useQuery } from "@apollo/client";

import { DashboardHeader } from "../../components/Headers";
import { Link } from "react-router-dom";
import { DataResultCard } from "../../components/Cards";
import { H3, P } from "../../components/Texts";
import noResultImg from "../../assets/no-result-img.svg";
import { OutlineButton } from "../../components/Buttons";

const FETCH_RESULTS = gql`
  query GetSearchResults($query: String!, $page: Int!, $limit: Int!) {
    search(text: $query, page: $page, limit: $limit) {
      data {
        id
        name
        topic
        title
        description
        access
        attribution
        attribution_url
        version
        author
        resources {
          path
          pathType
          name
          format
          encoding
          hash
        }
        createdAt
        updatedAt
        updatedLastBy
      }
    }
  }
`;

const DataResultPage = (props) => {
  const { query } = props.match.params;

  const [page, setPage] = useState(0);
  const [results, setResults] = useState([]);
  const goBack = () => window.history.back();

  const { loading, data, errors, fetchMore } = useQuery(FETCH_RESULTS, {
    variables: { query, page, limit: 9 },
  });

  useEffect(() => {
    if (data) {
      setResults(data.search.data);
      // calcPages(data.search.result_count);
      // setCount(data.search.result_count);
    }
  }, [data]);

  useEffect(() => {
    console.log(errors);
    if (errors) {
      setResults([]);
    }
  }, [errors]);

  useEffect(() => {
    // setLowerLimit((page + 1) * 9 - 8);
    // setUpperLimit((page + 1) * 9);
    fetchMore({
      variables: {
        page,
      },
      // updateQuery: (prev, { fetchMoreResult }) => {
      //   if (!fetchMoreResult) return prev;
      //   return Object.assign({}, prev, {
      //     search: {
      //       data: [...prev.search.data, ...fetchMoreResult.search.data],
      //     },
      //   });
      // },
    });
  }, [page]);

  return (
    <section className="max-h-screen overflow-y-auto">
      <DashboardHeader title={query} className="sticky top-0 z-10" />
      <div
        className="flex items-center cursor-pointer mb-2 pt-10 px-10 w-11/12"
        style={{
          width: "fit-content",
        }}
        onClick={goBack}
      >
        <Icon.ChevronLeft size={16} />
        <P className="ml-1">Back</P>
      </div>
      <section className="pt-5 pb-10 px-10">
        {!loading ? (
          <>
            {results?.length > 0 ? (
              <>
                <div className="grid md:grid-cols-3 grid-flow-row gap-6 mb-6">
                  {results?.map((result, idx) => (
                    <Link
                      key={idx}
                      to={`/dashboard/explore/source/details/${result.id}`}
                    >
                      <DataResultCard
                        key={idx}
                        type={"business"}
                        {...result}
                        // entry={entry}
                      />
                    </Link>
                  ))}
                </div>
                <div className="mt-10 flex items-center">
                  {/* <P>
                    Showing <span className="font-bold">{lowerLimit} </span>
                    to{" "}
                    <span className="font-bold">
                      {upperLimit > count ? count : upperLimit}
                    </span>{" "}
                    of
                    <span className="font-bold"> {count}</span>
                  </P> */}
                  <div className="flex justify-between items-center">
                    {/* {page !== 0 && (
                      <OutlineButton
                        color="#22202D"
                        className="block px-4 py-2 border text-sm font-medium rounded-md"
                        onClick={() => setPage(page - 1)}
                      >
                        Previous
                      </OutlineButton>
                    )} */}
                    {/* {page !== pages && ( */}
                    <OutlineButton
                      color="#22202D"
                      className="block ml-3 px-4 py-2 border text-sm font-medium rounded-md"
                      onClick={() => setPage(page + 1)}
                    >
                      Load more
                    </OutlineButton>
                    {/* )} */}
                  </div>
                </div>
              </>
            ) : (
              <div className="pt-20 flex flex-col items-center">
                <img src={noResultImg} alt="" className="" />
                <H3 color="#22202D" className="mt-10">
                  No results found
                </H3>
                <P fontSize="16px" color="" className="mt-2 mb-0 text-center">
                  We cannot locate an entry for your search.
                  <br />
                  <Link to="/dashboard/explore" className="ml-2 underline">
                    Please modify your search and try again
                  </Link>
                </P>
              </div>
            )}
          </>
        ) : (
          <div className="grid md:grid-cols-3 grid-flow-row gap-6 mb-6">
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
          </div>
        )}
      </section>
    </section>
  );
};

export default DataResultPage;
