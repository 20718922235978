import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { gql, useMutation } from "@apollo/client";

import { toastError } from "../lib/utils";
import { authContext } from "../store/AuthStore";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import eyeIcon from "../assets/eye.svg";
import eyeOffIcon from "../assets/eye-off.svg";
import check from "../assets/check.svg";
import dashboardLogo from "../assets/dashboardLogo.svg";
import loadIcon from "../assets/tail-spin.svg";

import { P, H6, TextLink, H5, H4 } from "../components/Texts";
import { DarkFormField, CheckBox } from "../components/Inputs";
import { PrimaryButton } from "../components/Buttons";
import { ThemeLessLayout } from "../components/Layout";
import FormCard from "../components/FormCard";

const checklist = [
  {
    heading: "Search for data",
    body: "Find the latest data on a variety of topics from A to Z.",
  },
  {
    heading: "Transform data",
    body: "Change data formats and clean data faster than ever.",
  },
  {
    heading: "Visualize",
    body: "Use built-in visualization tools to create shareable custom dashboards or export data to your preferred software.",
  },
];

const CheckItem = ({ heading, body }) => (
  <div className="flex mb-6">
    <img src={check} alt="" className="h-6 w-6 mr-4" />
    <div>
      <H5 color="#ffffff" fontSize="18px">
        {heading}
      </H5>
      <P color="#B4B0D1" className="mt-2" fontSize="14px">
        {body}
      </P>
    </div>
  </div>
);

const LOGIN_MUTATION = gql`
  mutation LoginMutation($data: LoginData) {
    login(data: $data) {
      token
      user {
        country
        email
        firstName
        id
        isVerified
        lastName
        password
        plan
        role
        # index
      }
    }
  }
`;
const LoginPage = () => {
  let history = useHistory();

  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState(false);
  const { auth } = useContext(authContext);

  const [logIn, { loading, error }] = useMutation(LOGIN_MUTATION);

  const loader = useRef(null);

  const onLogin = async (data) => {
    if (loader) {
      loader.current.continuousStart();
    }

    logIn({
      variables: {
        data: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then((data) => {
        const user_data = {
          loggedIn: true,
          user: { ...data.data.login.user },
          token: data.data.login.token,
        };
        localStorage.setItem("ssn_user", JSON.stringify(user_data));
        auth.updateAuth(user_data);
        history.push(
          history?.location?.state?.from?.pathname
            ? history?.location?.state?.from?.pathname
            : "/dashboard/explore",
        );
        return loader && loader.current?.complete();
      })
      .catch((error) => {
        return loader && loader.current?.complete();
      });
  };

  useEffect(() => {
    if (error && error.message.includes("401")) {
      toastError("User not found, check credentials.");
      return loader && loader.current?.complete();
    }
  }, [error]);

  const showPassword = () => {
    if (show) {
      setShow(false);
      setPasswordType("password");
    } else {
      setShow(true);
      setPasswordType("text");
    }
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="grid grid-cols-2 items-center">
        <div className="mx-auto w-3/5">
          <Link to="/" className="flex">
            <img src={dashboardLogo} alt="SocialSafety" className="mb-12" />
            <H4 className="pt-0.7 pl-1" color="#ffffff">
              DataLabs
            </H4>
          </Link>
          <div className="mt-20">
            {checklist.map((item, idx) => (
              <CheckItem key={idx} heading={item.heading} body={item.body} />
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <FormCard
            className="px-4 md:px-10 py-8 md:py-14 mx-auto w-3/5"
            maxW="476px"
          >
            <H6 color="#22202D" fontSize="18px" className="mb-8">
              Sign in to your Data Labs account
            </H6>

            <form onSubmit={handleSubmit(onLogin)}>
              <DarkFormField
                type="email"
                id="email"
                name="email"
                ref={register({
                  required: "Email is required.",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email.",
                  },
                })}
                placeholder="example@mail.com"
                label="Email"
                className="w-full"
              />

              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkFormField
                type={passwordType}
                id="password"
                name="password"
                ref={register({
                  required: "Password is required.",
                })}
                icon={!show ? eyeIcon : eyeOffIcon}
                iconClick={showPassword}
                placeholder="Use a strong password"
                label="Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <PrimaryButton className="w-full">
                {!loading ? (
                  "Sign in"
                ) : (
                  <img
                    src={loadIcon}
                    alt="Loading"
                    className="mx-auto h-6 w-6"
                  />
                )}
              </PrimaryButton>

              <div className="md:flex md:justify-between mt-4">
                <div className="flex items-center">
                  <CheckBox
                    ref={register()}
                    name="rememberMe"
                    id="rememberMe"
                    className="w-5 h-5"
                  />
                  <label htmlFor="rememberMe">
                    <P
                      fontSize="14px"
                      color="#2A2C3F"
                      className="ml-2 cursor-pointer"
                    >
                      Remember me
                    </P>
                  </label>
                </div>
                <Link to="/forgotPassword">
                  <TextLink color="#442ECF" fontFamily="semi">
                    Forgot Password?
                  </TextLink>
                </Link>
              </div>
            </form>

            <Link to="/register">
              <TextLink
                className="w-full mt-6 text-center block"
                color="#575B68"
                fontFamily="semi"
                // type="button"
              >
                Don't have an account?
                <span style={{ color: "#442ED0" }}> Sign up</span>
              </TextLink>
            </Link>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default LoginPage;
