import React, { useContext, useEffect, useState } from "react";

import { Route, useLocation, useRouteMatch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/dist/css/react-widgets.css";

import StoreProvider from "./store/Store";
import { ThemeProvider } from "./lib/themeContext";
import Home from "./pages/Index";
import LoginPage from "./pages/Login";
import SignUpPage from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import DashboardHome from "./pages/dashboard/Home";
import { authContext } from "./store/AuthStore";
import DrawerProvider from "./lib/drawerContext";
import { decodeToken } from "./lib/utils";
import ProtectedRoute from "./ProtectedRoute";
import PublicSearchPage from "./pages/public/SearchPage";
import SpecificResultPage from "./pages/public/SpecificResult";
import ViewData from "./pages/public/ViewData";
import Pricing from "./pages/public/Pricing";
import ContactPage from "./pages/public/ContactPage";
import PrivacyPage from "./pages/public/PrivacyPolicy";
import TermsPage from "./pages/public/TermsOfService";
import AboutUsPage from "./pages/public/AboutUs";
import NotFoundPage from "./pages/public/NotFoundPage";
import { CustomSwitch, RedirectToLostPage } from "./shared/routing";
import VerifyEmailPage from "./pages/VerifyEmail";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// const RouteManager  = ProviderHOC();

const App = () => {
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(true);

  const hasMatchedNotFound = useRouteMatch("/404");

  useEffect(() => {
    setIsValid(decodeToken());
  }, [auth]);

  return (
    <>
      {/* <RouteManager> */}
      <ScrollToTop />
      <DrawerProvider>
        <StoreProvider>
          <Route path="/404" component={NotFoundPage} />
          {!hasMatchedNotFound && (
            <CustomSwitch>
              <Route path="/privacy">
                <PrivacyPage />
              </Route>

              <Route path="/terms">
                <TermsPage />
              </Route>

              <Route path="/about">
                <AboutUsPage />
              </Route>

              <Route path="/contact">
                <ContactPage />
              </Route>

              <Route path="/pricing">
                <Pricing />
              </Route>

              <Route path="/view-data">
                <ViewData />
              </Route>

              <Route path="/search/details/:query">
                <SpecificResultPage />
              </Route>

              <Route path="/search/:query">
                <PublicSearchPage />
              </Route>

              <Route path="/forgotPassword">
                <ForgotPassword />
              </Route>

              <Route path="/verify/:email">
                <VerifyEmailPage />
              </Route>

              <Route path="/register">
                <SignUpPage />
              </Route>

              <Route path="/login">
                <LoginPage />
              </Route>

              <ProtectedRoute
                isValid={isValid}
                component={DashboardHome}
                path="/dashboard/"
              />

              <Route exact path="/">
                <ThemeProvider>
                  <Home />
                </ThemeProvider>
              </Route>

              <RedirectToLostPage />
            </CustomSwitch>
          )}
        </StoreProvider>
      </DrawerProvider>
      {/* </RouteManager> */}
    </>
  );
};

export default App;
