import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import * as Icon from "react-feather";

import LoaderHolder from "../../components/LoaderHolder";
import { P } from "../../components/Texts";
import { DashboardHeader } from "../../components/Headers";
import { useHistory } from "react-router";
import HandsOnTable from "../../components/HandsOnTable";

const customStyles = {
  table: {
    style: {
      // maxHeight: "480px",
      overflowY: "auto",
    },
  },
};

const ViewData = () => {
  const url = window.localStorage.getItem("datalabs_data_url");

  let history = useHistory();

  const goBack = () => history.goBack();

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const readDataFromUrl = (url) => {
      setLoading(true);
      fetch(url)
        .then((res) => {
          /* get the data as a Blob */
          if (!res.ok) throw new Error("fetch failed");
          return res.arrayBuffer();
        })
        .then((ab) => {
          /* parse the data when it is received */
          var _data = new Uint8Array(ab);
          var wb = XLSX.read(_data, { type: "array" });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          setFiles([
            // ...files,
            {
              name: "Howen.xslx",
              // size,
              // contents: JSON.parse(fileContents),
              // data_url: fileUrl,
              table_data: data,
              // parsedFile: processData(data),
            },
          ]);
          setSelectedFile(
            // ...files,
            {
              name: "Howen.xslx",
              // size,
              // contents: JSON.parse(fileContents),
              // data_url: fileUrl,
              table_data: data,
              // parsedFile: processData(data),
            },
          );
          setLoading(false);

          /* DO SOMETHING WITH workbook HERE */
        });
    };

    readDataFromUrl(url);
  }, [url]);

  return (
    <section className="relative h-screen overflow-y-hidden">
      <DashboardHeader title="View Data" className="sticky top-0" />
      <section
        className=""
        style={{
          height: "calc(100vh - 66px)",
        }}
      >
        <div className="p-10 sticky bg-white h-full flex flex-col">
          <div
            className="mb-5 flex items-center cursor-pointer flex-shrink-0"
            onClick={goBack}
            style={{
              // boxShadow: "0px 2px 2px #00000008",
              width: "fit-content",
              top: "66px",
            }}
          >
            <Icon.ChevronLeft color="#22202D" className="mr-2" />
            <P color="#22202D">Back</P>
          </div>
          {loading ? (
            <LoaderHolder message="Setting up data table, please be patient" />
          ) : (
            <>
              {files.length > 0 &&
                selectedFile !== null &&
                selectedFile.table_data && (
                  <HandsOnTable
                    pagination
                    highlightOnHover
                    fixedHeader={true}
                    striped
                    columns={selectedFile?.parsedFile?.columns}
                    data={selectedFile.table_data}
                    customStyles={customStyles}
                  />
                )}
            </>
          )}
        </div>
      </section>
      {/* <PreviewFileFooter
        // selectedTopic={selectedTopic}
        files={files}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      /> */}
    </section>
  );
};

export default ViewData;
