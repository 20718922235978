import React, { useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { gql, useLazyQuery } from "@apollo/client";

import { toastError, toastSuccess } from "../lib/utils";

import dashboardLogo from "../assets/dashboardLogo.svg";
import check from "../assets/check.svg";
import loadIcon from "../assets/tail-spin.svg";

import { P, H6, H5, H4 } from "../components/Texts";
import { OutlineButton, PrimaryButton } from "../components/Buttons";
import { ThemeLessLayout } from "../components/Layout";
import FormCard from "../components/FormCard";

const checklist = [
  {
    heading: "Search for data",
    body: "Find the latest data on a variety of topics from A to Z.",
  },
  {
    heading: "Transform data",
    body: "Change data formats and clean data faster than ever.",
  },
  {
    heading: "Visualize",
    body: "Use built-in visualization tools to create shareable custom dashboards or export data to your preferred software.",
  },
];

const CheckItem = ({ heading, body }) => (
  <div className="flex mb-6">
    <img src={check} alt="" className="h-6 w-6 mr-4" />
    <div>
      <H5 color="#ffffff" fontSize="18px">
        {heading}
      </H5>
      <P color="#B4B0D1" className="mt-2" fontSize="14px">
        {body}
      </P>
    </div>
  </div>
);

const RESEND_EMAIL_QUERY = gql`
  query ResendEmailQuery($email: String!) {
    resendEmailVerificationLink(email: $email) {
      ... on VerifyEmailResult {
        message
      }
      ... on MissingInputDetail {
        message
      }
      ... on VerifyEmailError {
        message
      }
    }
  }
`;

const VerifyEmailPage = () => {
  const { email } = useParams();

  const [resendEmail, { loading, data, error }] =
    useLazyQuery(RESEND_EMAIL_QUERY);

  const loader = useRef(null);

  const onResendEmail = async () => {
    if (loader) {
      loader.current.continuousStart();
    }

    resendEmail({
      variables: {
        email: email,
      },
    });
  };

  useEffect(() => {
    if (data) {
      switch (data.resendEmailVerificationLink.__typename) {
        case "MissingInputDetail":
          toastError(data.resendEmailVerificationLink.message);
          return loader && loader.current?.complete();

        case "VerifyEmailError":
          toastError(data.resendEmailVerificationLink.message);
          return loader && loader.current?.complete();

        default:
          toastSuccess(data.resendEmailVerificationLink.message);
          return loader && loader.current?.complete();
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="grid grid-cols-2 items-center">
        <div className="mx-auto w-3/5">
          <Link to="/" className="flex">
            <img src={dashboardLogo} alt="SocialSafety" className="mb-12" />
            <H4 className="pt-0.9 pl-1" color="#ffffff">
              DataLabs
            </H4>
          </Link>
          <div className="mt-20">
            {checklist.map((item, idx) => (
              <CheckItem key={idx} heading={item.heading} body={item.body} />
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <FormCard
            className="px-4 md:px-10 py-8 md:py-14 mx-auto"
            maxW="476px"
          >
            <H6 color="#22202D" fontSize="18px" className="mb-8">
              We need to verify your email
            </H6>
            <P color="#5F5D77">
              We sent an email to this email address
              <span
                className="semi mr-2 ml-2"
                style={{
                  color: "#22202D",
                }}
              >
                {email}
              </span>
              Verify your email to keep using Socialsafety.
            </P>
            <P color="#5F5D77" className="mt-4 mb-10">
              Click on the link in that email to verify your account. You may
              need to check your spam folder.
            </P>

            <PrimaryButton className="w-full" onClick={onResendEmail}>
              {!loading ? (
                "Don't see it? Resend"
              ) : (
                <img src={loadIcon} alt="Loading" className="mx-auto h-6 w-6" />
              )}
            </PrimaryButton>
            <Link to="/login">
              <OutlineButton
                className="w-full mt-4 block"
                color="#575B68"
                type="button"
              >
                Have an account?
                <span style={{ color: "#442ED0" }}> Sign in</span>
              </OutlineButton>
            </Link>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default VerifyEmailPage;
