import React, { useContext, useRef, useState, useEffect } from "react";
import t from "prop-types";
import { gql, useLazyQuery } from "@apollo/client";
import LoadingBar from "react-top-loading-bar";
import ReCaptchaV2 from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { toastSuccess } from "../../lib/utils";
import { authContext } from "../../store/AuthStore";
import { decodeToken } from "../../lib/utils";

import checkIcon from "../../assets/check.svg";

import { DataLabsHeader } from "../../components/Headers";
import { H2, H3, H4, P } from "../../components/Texts";
import CtaSection from "../../components/CtaSection";
import { BasicInput, BasicTextArea } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";
import Drawer from "../../components/Drawer";
import Footer from "../../components/LandingPageFooter";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const CheckList = ({ texts }) => (
  <>
    {texts.map((text, idx) => (
      <div key={idx} className="flex items-center mb-6">
        <img src={checkIcon} alt="" className="mr-6" />
        <P fontSize="24px" className="text-left" color="#fff">
          {text}
        </P>
      </div>
    ))}
  </>
);

CheckList.propTypes = {
  texts: t.array.isRequired,
};

const SEND_FEEDBACK_EMAIL = gql`
  query SendFeedbackEmail($name: String!, $email: String!, $message: String!) {
    sendFeedbackEmail(name: $name, email: $email, message: $message)
  }
`;

const ContactPage = () => {
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(false);
  const [token, setToken] = useState(null);
  const [formData, setFormData] = useState({});

  const loader = useRef(null);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  const { register, errors, handleSubmit, reset } = useForm({
    validateCriteriaMode: "all",
  });

  const [sendFeedbackQuery, { loading, error, data }] =
    useLazyQuery(SEND_FEEDBACK_EMAIL);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    return loader && loader.current?.complete();
  }, [error]);

  useEffect(() => {
    if (data?.sendFeedbackEmail === "ok") {
      toastSuccess("Your message was sent successfully");
      formData.reset();
    }
    return loader && loader.current?.complete();
  }, [data, formData, reset]);

  const handleToken = (token) => {
    setToken(token);
  };

  const handleExpire = () => {
    setToken(null);
  };

  const submitDetails = async (data, e) => {
    if (loader) {
      loader.current.continuousStart();
    }
    setFormData(e.target);

    sendFeedbackQuery({
      variables: {
        name: `${data.firstname} ${data.lastname}`,
        email: data.email,
        message: data.message,
      },
    });
  };

  return (
    <main>
      <DataLabsHeader bg="#fff" isValid={isValid} />
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <div className="md:hidden">
        <Drawer />
      </div>
      {/* tell us form */}
      <section
        className="container grid lg:grid-cols-2 gap-10 pt-20 md:pt-64 pb-0 md:pb-32 mb-10"
        style={{ backgroundColor: "#0C024D" }}
      >
        <div className="text-center lg:text-left">
          <H2
            color="#fff"
            fontSize="50px"
            fontFamily="semi"
            lineHeight="64px"
            className="md:w-5/6 mb-5 mx-auto lg:mx-0"
          >
            Tell us a bit about yourself, and we’ll tell you a lot more about
            us.
          </H2>
          <H4
            color="#B4B0D1"
            fontFamily="regular"
            className="md:w-5/6 mb-24 md:mx-auto lg:mx-0"
            fontSize="18px"
          >
            Let us know your goals and we’ll help you achieve them!
          </H4>
        </div>
        <form
          onSubmit={handleSubmit(submitDetails)}
          className="bg-white md:rounded md:shadow p-6 md:p-10 -mx-6 md:mx-0"
        >
          <H3
            color="#0C024D"
            fontSize="28px"
            className="mb-10 leading-10 text-center"
          >
            Tell us a bit about yourself, and we’ll tell you a lot more about
            us.
          </H3>
          <div className="mb-6">
            <BasicInput
              type="text"
              id="firstname"
              name="firstname"
              ref={register({
                required: "Please enter your first name.",
              })}
              className="w-full"
              placeholder="Your first name"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="firstname"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="text"
              id="lastname"
              name="lastname"
              ref={register({
                required: "Please enter your last name.",
              })}
              className="w-full"
              placeholder="Your last name"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="lastname"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicInput
              type="email"
              id="email"
              name="email"
              ref={register({
                required: "Please enter your email.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Enter a valid e-mail address",
                },
              })}
              className="w-full"
              placeholder="Work email"
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div className="mb-6">
            <BasicTextArea
              rows="4"
              id="message"
              name="message"
              ref={register({
                required: "Please enter what your message.",
              })}
              className="w-full"
              placeholder="Your message..."
              color="#0c024d"
            />
            <ErrorMessage
              errors={errors}
              name="message"
              as={<P fontSize="12px" className="mb-4 text-red-400 text-sm" />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <ReCaptchaV2
            sitekey={recaptchaSiteKey}
            onChange={handleToken}
            onExpired={handleExpire}
            type="image"
          />
          <PrimaryButton
            disabled={loading || !token}
            w="170px"
            smallW="100%"
            className="block mt-4"
          >
            {loading ? "Submitting" : "Submit"}
          </PrimaryButton>
        </form>
      </section>
      <CtaSection />
      <Footer />
    </main>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
