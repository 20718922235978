import React, { useEffect } from "react";
import * as Icon from "react-feather";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";

import PublicIcon from "../../assets/earth.svg";
import { DashboardHeader } from "../../components/Headers";
import { H5, TextLink } from "../../components/Texts";
import { Link } from "react-router-dom";

const FETCH_RESULT = gql`
  query GetSpecificResult($query: ID!) {
    getDataPackageById(id: $query) {
      id
      name
      title
      description
      topic
      access
      # country
      version
      author
      attribution
      attribution_url
      resources {
        path
        pathType
        name
        format
        encoding
        hash
      }
      createdAt
      updatedAt
      # updatedLastBy
    }
  }
`;

const SourceDetailsPage = (props) => {
  const { query } = props.match.params;

  const goBack = () => window.history.back();

  const { loading, data } = useQuery(FETCH_RESULT, {
    variables: { query },
  });

  useEffect(() => {
    window.localStorage.setItem(
      "datalabs_data_url",
      data?.getDataPackageById?.resources?.[0]?.path,
    );
    return () => {};
  }, [data]);

  return (
    <section className="max-h-screen overflow-y-auto">
      <DashboardHeader title="Datalabs" className="sticky top-0 z-10" />
      <div
        className="flex items-center cursor-pointer mb-2 pt-10 px-10 w-11/12"
        style={{
          width: "fit-content",
        }}
        onClick={goBack}
      >
        <Icon.ChevronLeft size={16} />
        <div className="ml-1 flex">
          <TextLink className="capitalize" color="#7D7B96">
            {data?.getDataPackageById?.topic?.toLowerCase()}
          </TextLink>
          <TextLink
            color="#22202D"
            fontSize="14px"
            className="capitalize font-bold"
          >
            <span>/{data?.getDataPackageById?.name}</span>
          </TextLink>
        </div>
      </div>
      <section className="py-10 px-10 flex w-full">
        {!loading ? (
          <>
            <div className="w-full mr-10">
              <div className="flex items-center">
                <TextLink
                  color="#7D7B96"
                  fontSize="14px"
                  className="w-1/3 mr-4 flex-shrink-0"
                >
                  Name
                </TextLink>
                <div className="flex-grow">
                  <TextLink
                    color="#22202D"
                    fontSize="14px"
                    className="capitalize font-bold"
                  >
                    {data?.getDataPackageById?.name}
                  </TextLink>
                </div>
              </div>
              <div className="flex items-center mt-4">
                <TextLink
                  color="#7D7B96"
                  fontSize="14px"
                  className="w-1/3 mr-4 flex-shrink-0"
                >
                  Title
                </TextLink>
                <div className="flex-grow">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="capitalize"
                  >
                    {data?.getDataPackageById?.title}
                  </TextLink>
                </div>
              </div>
              {data?.getDataPackageById?.description && (
                <div className="mt-4 flex">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Description
                  </TextLink>
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="flex-grow"
                  >
                    {data?.getDataPackageById?.description}
                  </TextLink>
                </div>
              )}
              <div className="mt-4 flex items-center">
                <TextLink
                  color="#7D7B96"
                  fontSize="14px"
                  className="w-1/3 mr-4 flex-shrink-0"
                >
                  Access
                </TextLink>
                <div className="flex-grow flex items-center ">
                  <img src={PublicIcon} alt="" className="mr-2 h-5" />
                  <TextLink
                    color="#22202D"
                    fontSize="14px"
                    className="capitalize"
                  >
                    {data?.getDataPackageById?.access}
                  </TextLink>
                </div>
              </div>
              {data?.getDataPackageById?.attribution && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Attribution
                  </TextLink>
                  <TextLink
                    color="#22202D"
                    fontSize="14px"
                    className="flex-grow"
                  >
                    {data?.getDataPackageById?.attribution}
                  </TextLink>
                </div>
              )}
              {data?.getDataPackageById?.attribution_url && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Attribution_link
                  </TextLink>
                  <a
                    href={`${data?.getDataPackageById?.attribution_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TextLink
                      color="#442ECF"
                      fontSize="14px"
                      className="flex-grow"
                    >
                      {data?.getDataPackageById?.attribution_link}
                    </TextLink>
                  </a>
                </div>
              )}
              {data?.getDataPackageById?.version && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Version
                  </TextLink>
                  <TextLink
                    color="#22202D"
                    fontSize="14px"
                    className="flex-grow"
                  >
                    {data?.getDataPackageById?.version}
                  </TextLink>
                </div>
              )}
              {data?.getDataPackageById?.author && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Author
                  </TextLink>
                  <TextLink
                    color="#22202D"
                    fontSize="14px"
                    className="flex-grow"
                  >
                    {data?.getDataPackageById?.author}
                  </TextLink>
                </div>
              )}
              {data?.getDataPackageById?.createdAt && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Created on
                  </TextLink>
                  <H5 color="#7D7B96" fontFamily="regular">
                    {moment(
                      data?.getDataPackageById?.createdAt,
                      "YYYY-MM-DD",
                    ).fromNow()}
                  </H5>
                </div>
              )}
              {data?.getDataPackageById?.updatedAt && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Last Updated
                  </TextLink>
                  <H5 color="#7D7B96" fontFamily="regular">
                    {moment(data?.getDataPackageById?.updatedAt).fromNow()}
                  </H5>
                </div>
              )}
              {data?.getDataPackageById?.resources?.length > 0 && (
                <div className="mt-4 flex items-center">
                  <TextLink
                    color="#7D7B96"
                    fontSize="14px"
                    className="w-1/3 mr-4 flex-shrink-0"
                  >
                    Download
                  </TextLink>
                  <div className="flex space-x-4">
                    {data?.getDataPackageById?.resources?.map(
                      (resource, idx) => (
                        <a key={idx} href={resource.path} download>
                          <TextLink
                            color="#0D024E"
                            fontSize="14px"
                            className="mr-4 flex-shrink-0 hover:text-indigo-800"
                          >
                            Download Dataset
                          </TextLink>
                        </a>
                      ),
                    )}
                  </div>
                </div>
              )}
              <div className="mt-4 flex">
                <TextLink
                  color="#7D7B96"
                  fontSize="14px"
                  className="w-1/3 mr-4 flex-shrink-0"
                >
                  View
                </TextLink>
                <div className="flex items-center">
                  <Link to="/dashboard/explore/view-data">
                    <TextLink
                      color="#0D024E"
                      fontSize="14px"
                      className="mr-4 flex-shrink-0 hover:text-indigo-800"
                    >
                      View Dataset
                    </TextLink>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full mr-10">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
          </div>
        )}
      </section>
    </section>
  );
};

export default SourceDetailsPage;
