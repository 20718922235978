/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import * as Icon from "react-feather";
import { gql, useQuery } from "@apollo/client";

import { DashboardHeader } from "../../components/Headers";
import { DataResultCard, SpecificDataCardStyle } from "../../components/Cards";
import { H5, P, TextLink } from "../../components/Texts";
import { Link } from "react-router-dom";

const FETCH_RESULT = gql`
  query GetSpecificResult($query: String!) {
    getDataPackageById(id: $query) {
      data {
        id
        name
        topic
        title
        description
        access
        # attribution
        # attribution_link
        version
        author
        resources {
          path
          pathType
          name
          format
          encoding
          hash
        }
        createdAt
        updatedAt
        updatedLastBy
      }
    }
  }
`;

const SpecificResultPage = (props) => {
  const { query } = props.match.params;

  const [entry] = useState({});

  const goBack = () => window.history.back();

  const { loading, data } = useQuery(FETCH_RESULT, {
    variables: { query },
  });

  return (
    <section className="max-h-screen overflow-y-auto">
      <DashboardHeader title={entry?.name} className="sticky top-0 z-10" />
      <div
        className="flex items-center cursor-pointer mb-2 pt-10 px-10 w-11/12"
        style={{
          width: "fit-content",
        }}
        onClick={goBack}
      >
        <Icon.ChevronLeft size={16} />
        <P className="ml-1">Back</P>
      </div>
      <section className="pt-5 pb-10 px-10 flex w-11/12">
        {!loading ? (
          <>
            <div className="w-2/6 mr-10">
              <DataResultCard shadowless {...data.search.data} />
            </div>
            <div className="flex-grow">
              {Array(2)
                .fill("")
                .map((_, idx) => (
                  <Link
                    key={idx}
                    to={`/dashboard/explore/source/details/${entry?.name}`}
                  >
                    <SpecificDataCardStyle
                      key={idx}
                      className="mb-8 px-10 py-6"
                    >
                      <H5 color="#22202D">Data Name</H5>
                      <div className="flex items-center mt-2">
                        <TextLink
                          color="#716E8B"
                          fontSize="13px"
                          className="mr-4"
                        >
                          Steve Anthony
                        </TextLink>
                        <TextLink color="#B4B0D1" fontSize="13px">
                          Created on 9 Sept. 2020
                        </TextLink>
                      </div>
                    </SpecificDataCardStyle>
                  </Link>
                ))}
            </div>
          </>
        ) : (
          <div className="mt-10 mb-8">
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
            <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
          </div>
        )}
      </section>
    </section>
  );
};

export default SpecificResultPage;
