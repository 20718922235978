import React from "react";
import { Link } from "react-router-dom";

import { OutlineButton, PrimaryButton } from "../../components/Buttons";
import { H1, P } from "../../components/Texts";

const NotFoundPage = () => {
  return (
    <main className="w-screen h-screen flex justify-center items-center">
      <section className="text-center md:w-8/12">
        <H1 color="#442ed0" className="mb-10">
          Page Not Found
        </H1>
        <P color="#0c024d" fontSize="20px" className="w-5/6 mx-auto">
          The page you are looking for might have been removed, had its name
          changed or temporary unavailable.
        </P>
        <div className="md:flex items-center justify-center mt-10">
          <Link to="/" className="md:mr-6 mb-8 md:mb-0 block">
            <PrimaryButton p="18px 24px" smallW="312px">
              Go to homepage
            </PrimaryButton>
          </Link>
          <Link to="/contact">
            <OutlineButton
              color="#442ed0"
              borderColor="#442ed0"
              p="18px 24px"
              w="161px"
              smallW="312px"
            >
              Contact Us
            </OutlineButton>
          </Link>
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
