import React from "react";
import styled from "styled-components";
import loaderImg from "../assets/loader.svg";

import { P } from "../components/Texts";

const LoaderHolderStyle = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #ffffff99;

  > div {
    background: #ffffff99;
    // width: 100%;
    // height: 100%;
  }
`;

const LoaderHolder = (props) => {
  return (
    <>
      <LoaderHolderStyle className="flex justify-center items-center">
        <div>
          <img src={loaderImg} alt="Loading" className="mb-6 mx-auto" />
          <P color="#0D024E">{props.message}</P>
        </div>
      </LoaderHolderStyle>
    </>
  );
};

export default LoaderHolder;
