import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";
import * as data from "frictionless.js";
import { useForm } from "react-hook-form";

import { StoreCtx } from "../../store/Store";

import loader from "../../assets/loader.svg";

import { P, TextLink, H5, H3 } from "../../components/Texts";
import { PrimaryButton } from "../../components/Buttons";
import { DashboardHeader } from "../../components/Headers";
import { InputFooter } from "../../components/Footers";
import { TopicCardStyle } from "../../components/Cards";

const Main = styled.div`
  max-height: calc(100vh - 147px);
  overflow-y: auto;
`;

const LoaderHolder = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #ffffff80;
`;

const UploadDataStep = (props) => {
  const { changeStep, updateNewData, discardNewData } = props;

  const store = useContext(StoreCtx);
  const { files } = store;

  const [loading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (store && files && files.length > 0) {
      changeStep("preview-files");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, store]);

  const addFile = (event) => {
    const { target } = event;
    const data = target.files[0]?.name;
    setFile(data);
  };

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    return { data: list, columns };
  };

  const readFileAsXlsx = (inputFile) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary", sheetRows: 400 });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        resolve(processData(data));
      };
      reader.readAsBinaryString(inputFile);
    });
  };

  const submitFile = async ({ file }) => {
    const { name, size } = file[0];
    const data_file = file[0];
    let parsed_File;

    try {
      if (
        name.includes(".xslx") ||
        name.includes(".csv") ||
        name.includes(".xls")
      ) {
        data.open(data_file);
        parsed_File = await readFileAsXlsx(data_file);
      } else {
        parsed_File = await readFileAsXlsx(data_file);
      }

      updateNewData({
        files: [
          ...store.files,
          {
            name,
            size,
            data_url: data_file,
            parsedFile: parsed_File,
          },
        ],
      });
      changeStep("preview-files");
    } catch (e) {
      console.error(e.message);
    }
  };

  const { register, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section className="relative">
      <DashboardHeader title="Datalabs" className="sticky top-0" />

      <Main className="pt-12 relative">
        {/* Notification Bar for success */}
        {/* {
          success && (
            <NotificationBar 
              success={true} 
              message="Data uploaded successfully"
            />
          )
        } */}

        <div className="w-1/2 mx-auto">
          <H3 color="#22202D" className="mb-2 text-center">
            Data Uploader
          </H3>
          <P fontSize="16" color="#716e8b" className="mb-16 text-center">
            Let's import your data in.
          </P>

          <div className="w-4/5 mx-auto">
            <form onSubmit={handleSubmit(submitFile)}>
              <TopicCardStyle
                as="div"
                className="py-16 px-20 flex flex-col justify-center items-center mx-auto"
                button="Upload file"
                border=" solid 2px #E7E5F2"
                hoverBorder=" solid 2px #E7E5F2"
              >
                <H5 color="#0D024E" className="mb-6">
                  Upload a CSV/Json/Xlsx file to get started
                </H5>
                {file && (
                  <TextLink className="-mt-4 mb-4 italic">{file}</TextLink>
                )}
                <label htmlFor="file" className="relative">
                  <PrimaryButton
                    as="div"
                    hoverBg="#332DBE"
                    className="cursor-pointer"
                  >
                    {!file ? "Upload file" : "Change file"}
                  </PrimaryButton>
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept=".csv,.json,.xlsx,.xls"
                  ref={register({
                    required: "Please upload a .csv, .json or excel file.",
                  })}
                  onChange={addFile}
                  className="w-0 h-0 opacity-0 absolute"
                />
              </TopicCardStyle>
            </form>
          </div>
        </div>
      </Main>

      <InputFooter
        submitDetails={handleSubmit(submitFile)}
        file={file}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      />

      {loading && (
        <LoaderHolder className="flex justify-center items-center">
          <div>
            <img src={loader} alt="Loading" className="mb-6 mx-auto" />
            <P color="#0D024E">Uploading data, please be patient</P>
          </div>
        </LoaderHolder>
      )}
    </section>
  );
};

export default UploadDataStep;
