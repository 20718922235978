import React, { useState, useEffect, useContext } from "react";
import { isEqual } from "lodash";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import * as data from "frictionless.js";

import { StoreCtx } from "../../store/Store";

import { P, H5, H3 } from "../../components/Texts";
import { PrimaryButton } from "../../components/Buttons";
import { DashboardHeader } from "../../components/Headers";
import { PreviewFileFooter } from "../../components/Footers";
import { FilesHolder, FileCard } from "../../components/Cards";

const customStyles = {
  table: {
    style: {
      maxHeight: "480px",
      overflowY: "auto",
    },
  },
};

const PreviewImports = (props) => {
  const {
    changeStep,
    updateNewData,
    discardNewData,
    // newData
  } = props;

  const store = useContext(StoreCtx);

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});

  useEffect(() => {
    if (store && store.files) setFiles(store.files);
  }, [store]);

  useEffect(() => {
    if (files.length > 0) setSelectedFile(files[0]);
  }, [files]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  const readFileAsXlsx = (inputFile) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary", sheetRows: 400 });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        resolve(processData(data));
      };
      reader.readAsBinaryString(inputFile);
    });
  };

  const addFile = async ({ target }) => {
    const { name, size } = target.files[0];
    const data_file = target.files[0];
    let parsed_File;
    try {
      if (
        name.includes(".xslx") ||
        name.includes(".csv") ||
        name.includes(".xls")
      ) {
        data.open(data_file);
        parsed_File = await readFileAsXlsx(data_file);
      } else {
        parsed_File = await readFileAsXlsx(data_file);
      }
      updateNewData({
        files: [
          ...store.files,
          {
            name,
            size,
            data_url: data_file,
            parsedFile: parsed_File,
          },
        ],
      });
      changeStep("preview-files");
    } catch (e) {
      console.error(e.message);
    }
  };

  const selectFile = (file) => {
    setSelectedFile(file);
  };

  const deleteFile = (file) => {
    let newFiles = files.filter((_file) => !isEqual(_file, file));
    setSelectedFile({});
    updateNewData({ files: newFiles });
  };

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/,
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    return { data: list, columns };
  };

  return (
    <section className="relative max-h-screen overflow-y-hidden">
      <DashboardHeader title="Datalabs" className="sticky top-0" />
      <section className="grid grid-cols-2">
        <div
          className="flex flex-col"
          style={{
            minHeight: "calc(100vh - 146px)",
          }}
        >
          <div
            className="py-6 px-10 sticky bg-white flex-shrink-0"
            style={{
              boxShadow: "0px 2px 2px #00000008",
              top: "66px",
            }}
          >
            <H3 color="#22202D" className="">
              Data Uploader
            </H3>
          </div>
          <div
            className="p-8 pb-24 flex-grow"
            style={{
              background: "#F8F7FA",
            }}
          >
            <FilesHolder className="p-4 pb-8">
              {!files.length > 0 ? (
                <div className="pt-8 flex flex-col items-center justify-center text-center">
                  <P className="mb-5">You have removed all your added files!</P>
                  <label
                    htmlFor="data_file"
                    className="mx-auto relative flex justify-end"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <PrimaryButton
                      bg="#442ECF10"
                      hoverBg="#442ECF25"
                      color="#442ECF"
                      p="10px 24px"
                      className="cursor-pointer mb-8"
                      as="div"
                      style={{
                        width: "fit-content",
                      }}
                    >
                      Add a file
                    </PrimaryButton>
                  </label>
                  <input
                    type="file"
                    name="data_file"
                    id="data_file"
                    accept=".csv,.json,.xlsx,.xls"
                    onChange={addFile}
                    className="w-0 h-0 opacity-0 absolute"
                  />
                </div>
              ) : (
                <>
                  {/* <label htmlFor="data_file" className="ml-auto relative flex justify-end"
                        style={{
                          width: "fit-content"
                        }}
                      >
                        <PrimaryButton
                          bg="#442ECF10"
                          hoverBg="#442ECF25"
                          color="#442ECF"
                          p="10px 24px"
                          className="cursor-pointer mb-8"
                          as="div"
                          style={{
                            width: "fit-content"
                          }}
                        >
                          Add more
                        </PrimaryButton>
                      </label>  
                      <input 
                        type="file"
                        name="data_file"
                        id="data_file"
                        accept=".csv,.json,.xlsx,.xls"
                        onChange={addFile}
                        className="w-0 h-0 opacity-0 absolute"
                      /> */}
                  <div className="flex items-center">
                    {files.map((_file, idx) => (
                      <FileCard
                        key={idx}
                        file={_file}
                        selected={isEqual(_file, selectedFile)}
                        selectFile={(file) => selectFile(file)}
                        deleteFile={(file) => deleteFile(file)}
                        name={_file.name}
                      />
                    ))}
                  </div>
                </>
              )}
            </FilesHolder>
          </div>
        </div>

        <div className="p-10">
          <div
            className="sticky bg-white mb-4"
            style={{
              top: "66px",
            }}
          >
            <H5 color="#13046D" fontSize="18px">
              Preview
            </H5>
          </div>

          {selectedFile && selectedFile.parsedFile ? (
            <DataTable
              pagination
              highlightOnHover
              columns={selectedFile.parsedFile.columns}
              data={selectedFile.parsedFile.data}
              customStyles={customStyles}
            />
          ) : (
            <P>Select a file to preview</P>
          )}
        </div>
      </section>
      <PreviewFileFooter
        // selectedTopic={selectedTopic}
        files={files}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      />
    </section>
  );
};

export default PreviewImports;
