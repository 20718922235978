import React from "react";
// import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// import { themeContext } from '../lib/themeContext';
// import usaFlag from "../../assets/usa-flag.svg";
// import swedenFlag from "../../assets/sweden-flag.svg";
// import spainFlag from "../../assets/spain-flag.svg";
// import franceFlag from "../../assets/france-flag.svg";
// import englandFlag from "../../assets/england-flag.svg";
import * as Icon from "react-feather";
import housingIcon from "../../assets/housing.svg";
import transportationIcon from "../../assets/transportation.svg";
import energyIcon from "../../assets/energy.svg";
import healthIcon from "../../assets/health.svg";
import foodIcon from "../../assets/food.svg";
import agricIcon from "../../assets/agric.svg";
import commoditiesIcon from "../../assets/commodities.svg";
import crimeIcon from "../../assets/crime.svg";
import demographicsIcon from "../../assets/demographics.svg";
import economicActivityIcon from "../../assets/economic-activity.svg";
import economyIcon from "../../assets/economy.svg";
import educationIcon from "../../assets/education.svg";
import environmentIcon from "../../assets/environment.svg";
import financialStatementsIcon from "../../assets/financial-statements.svg";
import foreignTradeIcon from "../../assets/foreign-trade.svg";
import goodServicesIcon from "../../assets/goods-services.svg";
import institutionsIcon from "../../assets/institutions.svg";
import landUseIcon from "../../assets/land-use.svg";
import petsIcon from "../../assets/pets.svg";
import povertyEquityIcon from "../../assets/poverty-equity.svg";
import sportsIcon from "../../assets/sports.svg";
import telecommunicationIcon from "../../assets/telecommunication.svg";
import waterIcon from "../../assets/water.svg";
import worldRankingsIcon from "../../assets/world-rankings.svg";
// import locationIcon from "../../assets/location.svg";
import searchSmallIcon from "../../assets/search-small.svg";

import { P, H6, TextLink, H1 } from "../../components/Texts";
import { DarkFormField } from "../../components/Inputs";
import { PrimaryButton } from "../../components/Buttons";

import { DashboardHeader } from "../../components/Headers";
import { SuggestionCard } from "../../components/Cards";

const ExplorePage = (props) => {
  let history = useHistory();

  const onSearch = (data) => {
    history.push(`/dashboard/explore/${data.need}`);
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section className="max-h-screen overflow-y-auto">
      <DashboardHeader title="Datalabs" className="sticky top-0" />
      <section className="p-20">
        <H1 color="#22202D" fontFamily="semi" className="mb-6">
          Explore
        </H1>
        <form onSubmit={handleSubmit(onSearch)}>
          <div className="flex gap-6">
            <div className="w-full md:w-1/3">
              <DarkFormField
                type="text"
                id="need"
                name="need"
                className="py-1"
                ref={register({
                  required: "Please enter a search phrase",
                })}
                placeholder="Enter a search phrase"
                iconBefore={searchSmallIcon}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />
              <ErrorMessage
                errors={errors}
                name="need"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>
            {/* 
          <div className="-mt-2 md:w-2/4">
            <div className="flex items-center">
              <DarkFormField
                type="text"
                id="location"
                name="location"
                containerClass="w-2/4 md:mb-0"
                className="py-1 w-full"
                ref={register({
                  required: "Please enter a location"
                })}
                placeholder="What’s your location?"
                iconBefore={locationIcon}
                iconBeforeClass=""
                inputClassName="-ml-3"
              />

              <TextLink
                color="#442ECF"
                fontFamily="semi"
                className="ml-4 cursor-pointer"
              >
                Use current location
              </TextLink>
            </div>
            
            <ErrorMessage 
              errors={errors} 
              name="location"  
              as={<P 
                fontSize="12px"
                className="-mt-4 mb-4 text-red-400 text-sm"
              />}
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type} >{message}</P>
                ))
              }
            </ErrorMessage>

            
          </div> */}

            <div>
              <PrimaryButton>Search</PrimaryButton>
            </div>
          </div>
        </form>

        <H6 color="#22202D" fontSize="18px" className="mt-16 mb-12">
          Categories
        </H6>

        <div className="px-2 md:px-0 w-full md:grid grid-cols-6  gap-6 grid-flow-row">
          <Link to="/dashboard/explore/agriculture">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={agricIcon} alt="Agric icon" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Agriculture</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/commodities">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={commoditiesIcon}
                alt="Commodities icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Commodities</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/crime">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={crimeIcon} alt="Crime icon" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Crime</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/demographics">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={demographicsIcon}
                alt="Demographics icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Demographics</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/economy">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={economyIcon}
                alt="Economy icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Economy</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/economic-activity">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={economicActivityIcon}
                alt="Economic-activity icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Economic Activity</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/education">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={educationIcon}
                alt="Education icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Education</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/energy">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={energyIcon} alt="" className="mb-4 mx-auto h-10 w-10" />
              <TextLink color="#47495C">Energy</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/environment">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={environmentIcon}
                alt="Environment icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Environment</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/financial-statements">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={financialStatementsIcon}
                alt="Financial-statements icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Financial Statements</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/food">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={foodIcon} alt="" className="mb-4 mx-auto h-10 w-10" />
              <TextLink color="#47495C">Food</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/foreign-trade">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={foreignTradeIcon}
                alt="Foreign-trade icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Foreign Trade</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/good-services">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={goodServicesIcon}
                alt="Good-services icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Goods and Services</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/health">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={healthIcon} alt="" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Health</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/housing">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={housingIcon} alt="" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Housing</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/institutions">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={institutionsIcon}
                alt="Institutions icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Institutions</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/land-use">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={landUseIcon}
                alt="Land use icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Land Use</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/pets">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={petsIcon} alt="Pets icon" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Pets</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/poverty-equity">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={povertyEquityIcon}
                alt="Poverty Equity icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Poverty and Equity</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/sports">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={sportsIcon}
                alt="Sports icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Sports</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/telecommunication">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={telecommunicationIcon}
                alt="Telecommunication icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">Telecommunication</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/tourism">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <Icon.MapPin className="mb-4 mx-auto h-10 w-10" color="#442ECF" />
              <TextLink color="#47495C">Tourism</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/transportation">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={transportationIcon}
                alt=""
                className="mb-4 mx-auto w-12 h-12"
              />
              <TextLink color="#47495C" className="text-center">
                Transportation
              </TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/water">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={waterIcon} alt="Water icon" className="mb-4 mx-auto" />
              <TextLink color="#47495C">Water</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/world-rankings">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img
                src={worldRankingsIcon}
                alt="World Rankings icon"
                className="mb-4 mx-auto"
              />
              <TextLink color="#47495C">World Rankings</TextLink>
            </SuggestionCard>
          </Link>
        </div>

        {/* <H6 color="#22202D" fontSize="18px" className="mt-16 mb-12">
          Featured Locations
        </H6>

        <div className="px-2 md:px-0 w-full md:grid grid-cols-6 gap-6 grid-flow-row">
          <Link to="/dashboard/explore/spain">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={spainFlag} alt="" className="small mb-4 mx-auto" />
              <TextLink color="#47495C">Spain</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/usa">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={usaFlag} alt="" className="small mb-4 mx-auto" />
              <TextLink color="#47495C">USA</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/england">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={englandFlag} alt="" className="small mb-4 mx-auto" />
              <TextLink color="#47495C">England</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/france">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={franceFlag} alt="" className="small mb-4 mx-auto" />
              <TextLink color="#47495C">France</TextLink>
            </SuggestionCard>
          </Link>

          <Link to="/dashboard/explore/sweden">
            <SuggestionCard className="text-center w-full cursor-pointer ">
              <img src={swedenFlag} alt="" className="small mb-4 mx-auto" />
              <TextLink color="#47495C">Sweden</TextLink>
            </SuggestionCard>
          </Link>
        </div>
       */}
      </section>
    </section>
  );
};

export default ExplorePage;
