import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { StoreCtx } from "../../store/Store";

import { P, H3 } from "../../components/Texts";
import {
  DarkFormField,
  DarkSelectInput,
  DarkTextArea,
} from "../../components/Inputs";
import { DashboardHeader } from "../../components/Headers";
import { DescriptionFooter } from "../../components/Footers";
import { topics, sub_topics } from "../../lib/data_topics";
import countries from "../../lib/countries";

const Main = styled.div`
  max-height: calc(100vh - 147px);
  overflow-y: auto;
`;

const DescriptionStep = (props) => {
  const { changeStep, updateNewData, discardNewData } = props;

  const store = useContext(StoreCtx);
  const {
    name,
    country,
    origin,
    origin_url,
    description,
    // encoding_type,
    // data_format,
    version,
    topic,
    // sub_topic,
  } = store;

  const [, setData] = useState({});
  const [, setSubTopics] = useState([]);

  useEffect(() => {
    // retrieveNewData();
    if (store && store.description)
      setData({
        name,
        country,
        origin,
        origin_url,
        description,
        // encoding_type,
        // data_format,
        topic,
        version,
        // sub_topic,
      });
  }, [
    description,
    country,
    version,
    // data_format,
    // encoding_type,
    name,
    origin,
    origin_url,
    store,
    // sub_topic,
    topic,
  ]);

  const submitDetails = (data) => {
    updateNewData(data);
    changeStep("publish");
  };

  const { register, errors, handleSubmit, watch } = useForm({
    validateCriteriaMode: "all",
    defaultValues: {
      country: country,
      // encoding_type: encoding_type,
      // data_format: data_format,
      topic: topic,
      // sub_topic: sub_topic,
      version: version,
    },
  });

  const selectedTopic = watch("topic");

  useEffect(() => {
    switch (selectedTopic) {
      case "AGRICULTURE":
        setSubTopics(sub_topics.AGRICULTURE);
        break;

      case "COMMODITIES":
        setSubTopics(sub_topics.COMMODITIES);
        break;

      case "CRIME":
        setSubTopics(sub_topics.CRIME);
        break;

      case "DEMOGRAPHICS":
        setSubTopics(sub_topics.DEMOGRAPHICS);
        break;

      case "ECONOMIC ACTIVITY":
        setSubTopics(sub_topics["ECONOMIC ACTIVITY"]);
        break;

      case "ECONOMY":
        setSubTopics(sub_topics.ECONOMY);
        break;

      case "EDUCATION":
        setSubTopics(sub_topics.EDUCATION);
        break;

      case "ENERGY":
        setSubTopics(sub_topics.ENERGY);
        break;

      case "ENVIRONMENT":
        setSubTopics(sub_topics.ENVIRONMENT);
        break;

      case "FINANCIAL STATEMENTS":
        setSubTopics(sub_topics["FINANCIAL STATEMENTS"]);
        break;

      case "FOREIGN TRADE":
        setSubTopics(sub_topics["FOREIGN TRADE"]);
        break;

      case "GOODS AND SERVICES":
        setSubTopics(sub_topics["GOODS AND SERVICES"]);
        break;

      case "HEALTH":
        setSubTopics(sub_topics.HEALTH);
        break;

      case "INSTITUTIONS":
        setSubTopics(sub_topics.INSTITUTIONS);
        break;

      case "LAND USE":
        setSubTopics(sub_topics["LAND USE"]);
        break;

      case "PETS":
        setSubTopics(sub_topics.PETS);
        break;

      case "POVERTY AND EQUITY":
        setSubTopics(sub_topics["POVERTY AND EQUITY"]);
        break;

      case "SPORTS":
        setSubTopics(sub_topics["SPORTS"]);
        break;

      case "TELECOMMUNICATION":
        setSubTopics(sub_topics["TELECOMMUNICATION"]);
        break;

      case "TOURISM":
        setSubTopics(sub_topics["TOURISM"]);
        break;

      case "WATER":
        setSubTopics(sub_topics["WATER"]);
        break;

      case "WORLD RANKINGS":
        setSubTopics(sub_topics["WORLD RANKINGS"]);
        break;

      default:
        setSubTopics([]);
        break;
    }
  }, [selectedTopic]);

  return (
    <section className="relative">
      <DashboardHeader title="Create a new data" className="sticky top-0" />

      <Main className="pt-12">
        <div className="w-1/2 mx-auto">
          <H3 color="#22202D" className="mb-2 text-center">
            Enter description
          </H3>
          <P fontSize="16" color="#716e8b" className="mb-16 text-center">
            Describe what your data is.
          </P>

          <form onSubmit={handleSubmit(submitDetails)} className="pb-20">
            <DarkFormField
              type="text"
              id="name"
              name="name"
              defaultValue={name}
              ref={register({
                required: "Name is required.",
              })}
              placeholder="Keep it short and simple"
              label="Name of Dataset"
              required
            />
            <ErrorMessage
              errors={errors}
              name="name"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkSelectInput
              className="pr-4"
              name="topic"
              id="topic"
              type="select"
              ref={register({
                required: "Topic is required.",
              })}
              label="Topic"
              required
            >
              <option value="" disabled selected={topic ? false : true}>
                Select a topic
              </option>
              {topics.map((_topic, idx) => (
                <option
                  key={idx}
                  value={_topic.value}
                  selected={_topic.value === topic}
                  className="capitalize"
                >
                  {_topic.value}
                </option>
              ))}
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="topic"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkSelectInput
              className="pr-4"
              name="country"
              id="country"
              type="select"
              ref={register({
                required: "Country is required.",
              })}
              label="Country"
              required
            >
              <option value="" disabled selected={country ? false : true}>
                Select a country
              </option>
              {countries.map((_country, idx) => (
                <option key={idx} value={_country.code3}>
                  {_country.name}
                </option>
              ))}
            </DarkSelectInput>
            <ErrorMessage
              errors={errors}
              name="country"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkFormField
              type="text"
              id="origin"
              name="origin"
              ref={register({
                required: "Data Origin is required.",
              })}
              placeholder="eg: Bureau of Information"
              label="Data Origin"
              defaultValue={origin}
              required
            />
            <ErrorMessage
              errors={errors}
              name="origin"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkFormField
              type="text"
              id="origin_url"
              name="origin_url"
              ref={register({
                required: "Data Origin Url is required.",
              })}
              placeholder="http://example.com"
              defaultValue={origin_url}
              label="Data Origin Url"
              required
            />
            <ErrorMessage
              errors={errors}
              name="origin_url"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkFormField
              type="number"
              id="version"
              name="version"
              ref={register({
                required: "Data Version is required.",
                min: {
                  value: 0.1,
                  message: "Minimum data version is 0.1",
                },
              })}
              placeholder="example: 1.0"
              defaultValue={version}
              label="Data Version"
              required
            />
            <ErrorMessage
              errors={errors}
              name="version"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>

            <DarkTextArea
              rows="4"
              className=""
              type="text"
              id="description"
              name="description"
              ref={register({
                required: "Description is required.",
              })}
              placeholder="Enter a description"
              defaultValue={description}
              label="Additional Description"
              required
            />
            <ErrorMessage
              errors={errors}
              name="description"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </form>
        </div>
      </Main>

      <DescriptionFooter
        submitDetails={handleSubmit(submitDetails)}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      />
    </section>
  );
};

export default DescriptionStep;
