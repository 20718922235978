import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import topics from "../../lib/topics";
import { StoreCtx } from "../../store/Store";

import briefcaseIcon from "../../assets/briefcase.svg";
import searchSmallIcon from "../../assets/search-small.svg";

import { P, TextLink, H5, H3 } from "../../components/Texts";
import { DarkFormField, DarkSelectDropDown } from "../../components/Inputs";
import { DashboardHeader } from "../../components/Headers";
import { NewEntryFooter } from "../../components/Footers";
import { TopicCard, PreviewItem } from "../../components/Cards";

const TopicSection = styled.div`
  &:last-child {
    margin-bottom: 50px;
  }

  @media (max-height: 665px) {
    // min-height: 6vh;
    max-height: 50vh;
    padding-bottom: 32px;
  }

  @media (min-height: 700px) {
    min-height: 67vh;
    max-height: 66vh;
  }

  @media (min-height: 898px) {
    min-height: 70vh;
    max-height: 70vh;
  }
`;

const SelectTopicStep = (props) => {
  const { changeStep, updateNewData, discardNewData } = props;

  const store = useContext(StoreCtx);

  const [, setTopic] = useState("Select Topic");
  const [selectedTopic, setSelectedTopic] = useState(null);

  useEffect(() => {
    if (store && store.topic) setSelectedTopic(store.topic);
  }, [store]);

  const searchEntry = (data) => {
    return;
  };

  const changeSelectedTopic = (topic) => {
    updateNewData({ topic: topic });
    setSelectedTopic(topic);
  };

  const changeTopic = (option) => setTopic(option);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section className="relative max-h-screen overflow-y-hidden">
      <DashboardHeader title="Create a new data" className="sticky top-0" />
      <section className="grid grid-cols-2">
        <div>
          <div
            className="pt-6 px-8 sticky bg-white"
            style={{
              boxShadow: "0px 2px 2px #00000008",
              top: "66px",
            }}
          >
            <H3 color="#22202D" className="mb-2">
              Select a topic
            </H3>
            <P color="#716E8B" className="mb-6" fontSize="16px">
              Choose a data type similar to your data.
            </P>
            <div className="grid grid-cols-2 gap-6 w-11/12">
              <form onSubmit={handleSubmit(searchEntry)}>
                <DarkFormField
                  type="search"
                  id="entry"
                  name="entry"
                  className="py-1"
                  ref={register({
                    required: "",
                  })}
                  required
                  placeholder="Search"
                  iconBefore={searchSmallIcon}
                  iconBeforeClass=""
                  inputClassName="-ml-3"
                />
                <ErrorMessage
                  errors={errors}
                  name="need"
                  as={
                    <P
                      fontSize="12px"
                      className="-mt-4 mb-4 text-red-400 text-sm"
                    />
                  }
                >
                  {({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <P key={type}>{message}</P>
                    ))
                  }
                </ErrorMessage>
              </form>

              <DarkSelectDropDown
                toggleBg="#F8F6FE"
                toggleColor="#9490B1"
                optionColor="#6A6E8A"
                optionBg="#ffffff"
                options={[
                  "Select topic",
                  "Care (5)",
                  "Food (20)",
                  "Health (30)",
                  "Legal (15)",
                  "Education (5)",
                  "Goods (4)",
                  "Housing (45)",
                  "Money (50)",
                ]}
                changeView={(option) => changeTopic(option)}
              />
            </div>
          </div>
          <TopicSection
            className="p-8 pb-24 overflow-y-auto"
            style={{
              background: "#F8F7FA",
            }}
          >
            {topics.map((section, idx) => (
              <React.Fragment key={idx}>
                <H5
                  className="mb-4"
                  color="#716E8B"
                  fontFamily="medium"
                  fontSize="12px"
                >
                  {section.sectionTitle}
                </H5>

                {section.topics.map((topic, idx) => (
                  <TopicCard
                    key={idx}
                    className="mb-4"
                    img={briefcaseIcon}
                    title={topic.title}
                    subTitle={topic.subtitle}
                    selected={isEqual(selectedTopic, topic)}
                    topic={topic}
                    onClick={(topic) => changeSelectedTopic(topic)}
                  />
                ))}
              </React.Fragment>
            ))}
          </TopicSection>
        </div>

        <div className="p-10">
          <div
            className="sticky bg-white"
            style={{
              top: "66px",
            }}
          >
            <H5 color="#13046D" fontSize="18px">
              Preview
            </H5>
            <TextLink color="#9490B1" className="mt-2 mb-12">
              Your data will have these fields.
            </TextLink>
          </div>

          {selectedTopic && (
            <TopicSection className="pb-16 overflow-y-auto pr-4">
              {selectedTopic.fields.map((fields, idx) => (
                <PreviewItem key={idx} {...fields} />
              ))}
            </TopicSection>
          )}
        </div>
      </section>
      <NewEntryFooter
        selectedTopic={selectedTopic}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      />
    </section>
  );
};

export default SelectTopicStep;
