import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { PrimaryButton } from "../../../components/Buttons";
import { DashboardHeader } from "../../../components/Headers";
import Table from "../../../components/Table/table";
import { H3, H6, P } from "../../../components/Texts";
import { FETCH_API_KEYS, CREATE_API_KEY } from "../../../shared/queries";
import { toastError, toastSuccess, toastWarning } from "../../../lib/utils";
import { authContext } from "../../../store/AuthStore";
import { useContext } from "react";

const APIKeysPage = () => {
  const { auth } = useContext(authContext);

  const checkVerified = () => toastWarning("Please verify your email first");

  const { loading, data, refetch: refetchKeys } = useQuery(FETCH_API_KEYS);

  const [
    createKey,
    { loading: creating, data: keyCreationData, error: keyCreationError },
  ] = useMutation(CREATE_API_KEY);

  const onCreateKey = () => {
    createKey();
  };

  useEffect(() => {
    if (keyCreationData) {
      switch (keyCreationData.createApiKey.__typename) {
        case "APIOutOfBounds":
          toastError(keyCreationData.createApiKey.message);
          break;

        case "PlanExistError":
          toastError(keyCreationData.createApiKey.message);
          break;

        default:
          toastSuccess("Api Key created successfully");
          refetchKeys();
          break;
      }
    }
  }, [keyCreationData, refetchKeys]);

  useEffect(() => {
    if (keyCreationError) {
      console.log(keyCreationError);
    }
  }, [keyCreationError]);

  return (
    <section>
      <DashboardHeader title="Datalabs" className="sticky top-0" />
      <section className="px-8 py-6">
        <div className="flex justify-between items-center mb-10">
          <H3>API keys</H3>
          {auth?.user?.isVerified ? (
            <PrimaryButton
              className=""
              onClick={onCreateKey}
              disabled={creating}
            >
              {creating ? "Generating" : "Generate new keys"}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className=""
              onClick={checkVerified}
              disabled={creating}
            >
              Generate new keys
            </PrimaryButton>
          )}
        </div>
        {data?.getApiKeys?.appId && (
          <div className="flex items-center mb-10">
            <H6 className="mr-4">App ID:</H6>
            <P>{data?.getApiKeys?.appId}</P>
          </div>
        )}
        <Table
          loading={loading}
          data={data?.getApiKeys?.keys}
          refetchKeys={refetchKeys}
        />
        {data?.getApiKeys?.keys?.length > 0 && (
          <P color="#1E202A" fontFamily="medium" className="mt-10">
            {data?.getApiKeys?.keys?.length} Developer keys
          </P>
        )}
      </section>
    </section>
  );
};

export default APIKeysPage;
