import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { gql, useMutation } from "@apollo/client";

import { toastError, toastSuccess } from "../lib/utils";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import dashboardLogo from "../assets/dashboardLogo.svg";
import eyeOffIcon from "../assets/eye-off.svg";
import eyeIcon from "../assets/eye.svg";
import check from "../assets/check.svg";
import loadIcon from "../assets/tail-spin.svg";

import { P, H6, TextLink, H5, H4 } from "../components/Texts";
import { DarkFormField, DarkSelectInput } from "../components/Inputs";
import { OutlineButton, PrimaryButton } from "../components/Buttons";
import { ThemeLessLayout } from "../components/Layout";
import FormCard from "../components/FormCard";
import countries from "../lib/countries";

const checklist = [
  {
    heading: "Search for data",
    body: "Find the latest data on a variety of topics from A to Z.",
  },
  {
    heading: "Transform data",
    body: "Change data formats and clean data faster than ever.",
  },
  {
    heading: "Visualize",
    body: "Use built-in visualization tools to create shareable custom dashboards or export data to your preferred software.",
  },
];

const CheckItem = ({ heading, body }) => (
  <div className="flex mb-6">
    <img src={check} alt="" className="h-6 w-6 mr-4" />
    <div>
      <H5 color="#ffffff" fontSize="18px">
        {heading}
      </H5>
      <P color="#B4B0D1" className="mt-2" fontSize="14px">
        {body}
      </P>
    </div>
  </div>
);

const SIGNUP_MUTATION = gql`
  mutation SignupMutation($data: RegisterData) {
    register(data: $data) {
      token
    }
  }
`;

const SignUpPage = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState(false);

  const [signUp, { loading }] = useMutation(SIGNUP_MUTATION);

  let history = useHistory();

  const loader = useRef(null);

  const onSignUp = async (data) => {
    // setLoading(true);
    if (loader) {
      loader.current.continuousStart();
    }

    signUp({
      variables: {
        data: {
          firstName: data.firstname,
          lastName: data.lastname,
          email: data.email,
          country: data.country,
          password: data.password,
          // index: "datalabs",
          role: "writer",
        },
      },
    })
      .then(() => {
        toastSuccess("User created succesfully");
        history.push(`/verify/${data.email}`);
      })
      .catch((error) => {
        toastError(error.message);
        return loader && loader.current?.complete();
      });
  };

  const showPassword = () => {
    if (show) {
      setShow(false);
      setPasswordType("password");
    } else {
      setShow(true);
      setPasswordType("text");
    }
  };

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <ThemeLessLayout className="flex">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <section className="grid grid-cols-2 items-center">
        <div className="mx-auto w-3/5">
          <Link to="/" className="flex">
            <img src={dashboardLogo} alt="SocialSafety" className="mb-12" />
            <H4 className="pt-0.9 pl-1" color="#ffffff">
              DataLabs
            </H4>
          </Link>
          <div className="mt-20">
            {checklist.map((item, idx) => (
              <CheckItem key={idx} heading={item.heading} body={item.body} />
            ))}
          </div>
        </div>
        <div className="flex items-center">
          <FormCard
            className="px-4 md:px-10 py-8 md:py-14 mx-auto"
            maxW="476px"
          >
            <H6 color="#22202D" fontSize="18px" className="mb-8">
              Create your Data Labs account
            </H6>

            <form onSubmit={handleSubmit(onSignUp)}>
              <DarkFormField
                type="text"
                id="firstname"
                name="firstname"
                w="135px"
                ref={register({
                  required: "First name is required",
                  minLength: {
                    value: 2,
                    message: "Please enter your first name",
                  },
                })}
                placeholder="Enter your first name"
                label="First Name"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="firstname"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkFormField
                type="text"
                id="lastname"
                name="lastname"
                w="135px"
                ref={register({
                  required: "Last Name is required",
                  minLength: {
                    value: 2,
                    message: "Please enter your last name",
                  },
                })}
                placeholder="Enter your last name"
                label="Last Name"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="lastname"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkSelectInput
                className="pr-4"
                name="country"
                id="country"
                type="select"
                ref={register({
                  required: "Country is required.",
                })}
                label="Country"
              >
                <option value="" disabled selected>
                  Select a country
                </option>
                {countries.map((_country, idx) => (
                  <option key={idx} value={_country.code3}>
                    {_country.name}
                  </option>
                ))}
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="country"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkFormField
                type="email"
                id="email"
                name="email"
                w="135px"
                ref={register({
                  required: "Email is required",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email.",
                  },
                })}
                placeholder="Enter your email addresss"
                label="Email Address"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <DarkFormField
                type={passwordType}
                id="password"
                name="password"
                w="135px"
                ref={register({
                  required: "Password is required.",
                  minLength: {
                    value: 10,
                    message: "Password must be at least 10 characters",
                  },
                })}
                icon={!show ? eyeIcon : eyeOffIcon}
                iconClick={showPassword}
                placeholder="Use a strong password"
                label="Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="password"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>

              <PrimaryButton className="w-full">
                {!loading ? (
                  "Create account"
                ) : (
                  <img
                    src={loadIcon}
                    alt="Loading"
                    className="mx-auto h-6 w-6"
                  />
                )}
              </PrimaryButton>
            </form>
            <Link to="/login">
              <OutlineButton
                className="w-full mt-4 block"
                color="#575B68"
                type="button"
              >
                Have an account?
                <span style={{ color: "#442ED0" }}> Sign in</span>
              </OutlineButton>
            </Link>
            <TextLink
              className="w-full mt-10 text-center block"
              color="#575B68"
              fontFamily="semi"
              // type="button"
            >
              Need some help?
              <span style={{ color: "#442ED0" }}> Chat now</span> or contact us
              <a href="mailto:" style={{ color: "#442ED0" }}>
                {" "}
                via mail
              </a>
            </TextLink>
          </FormCard>
        </div>
      </section>
    </ThemeLessLayout>
  );
};

export default SignUpPage;
