const topics = [
  {
    sectionTitle: "JUSTICE",
    topics: [
      {
        title: "Civil Process",
        subtitle:
          "The issuance of a notice of initial legal action to another party.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique Id for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "PROPERTY",
    topics: [
      {
        title: "Building Inspection",
        subtitle:
          "Building inspection report created by a constituent or a government worker.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
      {
        title: "Government Property",
        subtitle: "Government owned and operated property.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
      {
        title: "Building Demolition",
        subtitle: "Scheduled demolition of a building.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
      {
        title: "Building Inspection",
        subtitle:
          "Building inspection report created by a constituent or a government worker.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
      {
        title: "Government Property",
        subtitle: "Government owned and operated property.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
      {
        title: "Building Demolition",
        subtitle: "Scheduled demolition of a building.",
        fields: [
          {
            _key: "id",
            title: "ID",
            type: "Text",
            description: "Unique ID for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Issued",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Disposition",
            type: "Date",
            description: "Unique Date for this piece of data",
            notEmpty: "Date",
            required: true,
          },
          {
            _key: "id",
            title: "Name",
            type: "Text",
            description: "Unique Name for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Type",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Notes",
            type: "Text",
            description: "Unique Type for this piece of data",
            notEmpty: "Text",
            required: true,
          },
          {
            _key: "id",
            title: "Operators",
            type: "Array",
            description: "Unique operators for this piece of data",
            notEmpty: "Array",
            required: true,
          },
        ],
      },
    ],
  },
];

export default topics;
