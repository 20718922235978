/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { H4, H5, P, TextLink } from "./Texts";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

import navToggleLight from "../assets/menuLight.svg";
import navToggleDark from "../assets/menuDark.svg";
import moonIcon from "../assets/ios-moon.svg";
import sunIcon from "../assets/sunIcon.svg";
import logo from "../assets/logo.svg";
import darkLogo from "../assets/darkLogo.svg";

import { themeContext } from "../lib/themeContext";
import { authContext } from "../store/AuthStore";
import { HeaderButton, OutlineButton } from "./Buttons";

const HeaderStyle = styled.header`
  // width: 100vw;
  max-width: 100vw;
  padding: 20px;
  padding-left: 138px;
  padding-right: 138px;
  z-index: 999;

  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  background: ${(props) => props.bg};
`;

const ThemeToggle = styled.button`
  img {
    width: 25px;
    height: 27px;
  }

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
    background: #442ecf10;
  }
`;

const MenuToggle = styled.button`
  img {
    width: 25px;
    height: 40px;
  }

  &:focus {
    border-color: #442ecf50;
    outline-color: #442ecf50;
    background: #442ecf10;
  }
`;

const Header = (props) => {
  const { theme, toggleTheme, isDark } = useContext(themeContext);

  const toggleMenu = () => {};

  return (
    <HeaderStyle
      bg={theme.background}
      className="sticky inset-0 mx-auto flex justify-between items-center"
    >
      {/* LOGO */}
      {!isDark ? (
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      ) : (
        <Link to="/">
          <img src={darkLogo} alt="" />
        </Link>
      )}

      {/* Right Navbar */}
      <nav className="hidden md:flex justify-between items-center">
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>
      </nav>

      {/* Mobile Navbar */}
      <nav className="flex md:hidden  justify-between items-center">
        <ThemeToggle role="button" className="p-2" onClick={toggleTheme}>
          <img
            src={isDark ? sunIcon : moonIcon}
            alt=""
            className="object-cover"
          />
        </ThemeToggle>

        <MenuToggle role="button" className="p-2" onClick={toggleMenu}>
          <img
            src={isDark ? navToggleLight : navToggleDark}
            alt=""
            className="object-cover"
          />
        </MenuToggle>
      </nav>
    </HeaderStyle>
  );
};

export default Header;

const ProfileDropDownToggleStyle = styled.div`
  position: relative;
`;

const ProfileDropDownStyle = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  z-index: 50;
  width: fit-content;
  min-width: 300px;

  .arrow {
    position: absolute;
    z-index: 1;
    top: -10px;
    right: 10px;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
  }

  li.hoverable {
    :hover {
      background: #eee;
      color: #442ed0;
    }
  }

  li {
    list-style-type: none;
  }
`;

const ProfileDropDown = ({ toggleDropDown, auth }) => {
  const checkIfNotInDropDown = ({ target }) => {
    if (!target.classList.contains("profile-dropdown-child")) {
      toggleDropDown();
    }
  };

  useEffect(() => {
    window.addEventListener("click", checkIfNotInDropDown);

    return () => {
      window.removeEventListener("click", checkIfNotInDropDown);
    };
  }, []);

  return (
    <ProfileDropDownStyle className="shadow-lg rounded-md cursor-default">
      <div className="profile-dropdown-child arrow shadow-lg" />
      <ul className="profile-dropdown-child relative z-10 py-6 px-4 bg-white">
        <li className="profile-dropdown-child p-2 mb-6">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center object-cover mb-2 profile-dropdown-child"
            style={{ background: "#EAE7FE" }}
          >
            <H5 color="#12046D" className="capitalize profile-dropdown-child">
              {`${auth?.user?.firstName?.[0]} ${auth?.user?.lastName?.[0]}`}
            </H5>
          </div>
          <P
            color="#442ED0"
            fontFamily="medium"
            className="profile-dropdown-child"
          >
            {`${auth?.user?.firstName} ${auth?.user?.lastName}`}
          </P>
          <P color="#575B68" fontSize="12px" className="profile-dropdown-child">
            {auth?.user?.email}
          </P>
        </li>
        <Link to="/dashboard/profile">
          <li className="hoverable duration-300 p-2 cursor-pointer">
            <P className="profile-dropdown-child flex justify-between items-center">
              Profile
            </P>
          </li>
        </Link>
        <Link to="/dashboard/apikeys">
          <li className="hoverable duration-300 p-2 cursor-pointer">
            <P className="profile-dropdown-child flex justify-between items-center">
              API Keys
            </P>
          </li>
        </Link>
        <li
          className="hoverable duration-300 p-2 mt-10 cursor-pointer"
          onClick={auth.logOut}
        >
          <P className="profile-dropdown-child flex justify-between items-center">
            Log Out
            <Icon.Power size={16} />
          </P>
        </li>
      </ul>
    </ProfileDropDownStyle>
  );
};

const DashboardHeaderStyle = styled.header`
  background: ${(props) => props.bg || "#ffffff"};
  border-bottom: 2px solid #f5f4fa;
`;

export const DashboardHeader = ({ className, title }) => {
  const { auth } = useContext(authContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropDown = () => setShowDropDown(!showDropDown);

  return (
    <DashboardHeaderStyle
      className={`${className} z-20 flex justify-between items-center py-3 px-10`}
    >
      <H5 color="#0D024E" className="capitalize">
        {title}
      </H5>
      <div className="flex items-center">
        {/* <div>
          <Link to="/dashboard/plan">
            <HeaderButton className="mx-10">Upgrade</HeaderButton>
          </Link>
        </div> */}

        {/* NOTIFICATION ICON */}
        <ProfileDropDownToggleStyle className="cursor-pointer">
          <div onClick={toggleDropDown} className="flex items-center">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center object-cover"
              style={{ background: "#EAE7FE" }}
            >
              <H5 color="#12046D" className="capitalize">
                {`${auth?.user?.firstName?.[0]} ${auth?.user?.lastName?.[0]}`}
              </H5>
            </div>
            <H5 color="#000000" className="capitalize mx-4">
              {auth?.user?.firstName + " " + auth?.user?.lastName}
            </H5>
            <Icon.ChevronDown size={16} className="" color="#767E85" />
          </div>
          {showDropDown && (
            <ProfileDropDown auth={auth} toggleDropDown={toggleDropDown} />
          )}
        </ProfileDropDownToggleStyle>
      </div>
    </DashboardHeaderStyle>
  );
};

const DataLabsHeaderStyle = styled.header`
  // width: 100vw;
  max-width: 100vw;
  // padding: 20px;
  padding-left: 96px;
  padding-right: 96px;
  z-index: 999;
  box-shadow: ${(props) => props.scrolled && "0px 1px 3px #3f3f4429"};
  transition: background 0.3s ease-in-out;

  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  background: ${(props) => props.bg};
`;

export const DataLabsHeader = ({ title, isValid, bg }) => {
  // const { toggleDrawer } = useContext(DrawerContext);

  // const { theme, toggleTheme, isDark } = useContext(themeContext);
  const isDark = false;

  const [isScrolled, setIsScrolled] = useState(false);

  const checkPosition = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const checkPath = (path) => {
    if (window.location.pathname === path) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener("load", checkPosition);
    window.addEventListener("scroll", checkPosition);

    return () => {
      window.removeEventListener("load", checkPosition);
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  return (
    <DataLabsHeaderStyle
      bg={bg ? bg : isScrolled ? "#fff" : "transparent"}
      scrolled={isScrolled}
      className="fixed top-0 left-0 right-0 py-4 md:pt-8 md:pb-5 mx-auto flex justify-between items-center"
    >
      {/* LOGO */}
      {!isDark ? (
        <Link to="/" className="flex">
          <img src={logo} alt="" />
          <H4 className="pt-0.7 pl-1" color="#0C024D" fontSize="21px">
            {title}
          </H4>
        </Link>
      ) : (
        <Link to="/">
          <img src={logo} alt="" />
          <H4 className="pt-0.7 pl-1" color="#0C024D" fontSize="21px">
            {title}
          </H4>
        </Link>
      )}
      <div className="hidden md:flex items-center ml-10">
        {/* <Link to="/documentation" className="mr-10">
          {checkPath("/documentation") ? (
            <HeaderButton
              color="#ffffff"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Documentation
            </HeaderButton>
          ) : (
            <TextLink
              color="#0C024D"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Documentation
            </TextLink>
          )}
        </Link> */}
        <Link to="/pricing" className="mr-10">
          {checkPath("/pricing") ? (
            <HeaderButton
              color="#ffffff"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Pricing and Plans
            </HeaderButton>
          ) : (
            <TextLink
              color="#0C024D"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Pricing and Plans
            </TextLink>
          )}
        </Link>
        <Link to="/contact" className="mr-10">
          {checkPath("/contact") ? (
            <HeaderButton
              color="#ffffff"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Contact us
            </HeaderButton>
          ) : (
            <TextLink
              color="#0C024D"
              fontSize="16px"
              p="9px 16px"
              className="rounded"
            >
              Contact us
            </TextLink>
          )}
        </Link>
      </div>

      {/* Right Navbar */}
      <nav className="hidden md:flex justify-between items-center">
        {!isValid ? (
          <>
            <Link to="/login" className="mr-6">
              <OutlineButton color="#0C024D" p="20px 24px" w="161px">
                Sign In
              </OutlineButton>
            </Link>
            <Link to="/register" className="">
              <HeaderButton p="20px 24px">Create Account</HeaderButton>
            </Link>
          </>
        ) : (
          <Link to="/dashboard/explore" className="mr-6">
            <OutlineButton color="#0C024D" p="20px 24px">
              Go to Dashboard
            </OutlineButton>
          </Link>
        )}
      </nav>
    </DataLabsHeaderStyle>
  );
};
