export const topics = [
  {
    value: "Agriculture",
  },
  {
    value: "Commodities",
  },
  {
    value: "Crime",
  },
  {
    value: "Demographics",
  },
  {
    value: "Economic Activity",
  },
  {
    value: "Economy",
  },
  {
    value: "Education",
  },
  {
    value: "Energy",
  },
  {
    value: "Environment",
  },
  {
    value: "Financial Statements",
  },
  {
    value: "Foreign Trade",
  },
  {
    value: "Goods and Services",
  },
  {
    value: "Health",
  },
  {
    value: "Institutions",
  },
  {
    value: "Land Use",
  },
  {
    value: "Pets",
  },
  {
    value: "Poverty and Equity",
  },
  {
    value: "Sports",
  },
  {
    value: "Telecommunication",
  },
  {
    value: "Tourism",
  },
  {
    value: "Water",
  },
  {
    value: "World Rankings",
  },
  {
    value: "Other",
  },
];

export const sub_topics = {
  Agriculture: [
    "Agricultural Products",
    "Agriculture Indicators",
    "Crops",
    "Livestock",
    "Water Resources",
  ],
  Commodities: [
    "Agriculture Bio Fuels",
    "Chemical Compounds",
    "Commodities Indicators",
    "Forest Products",
    "Natural resources Price",
    "Raw Materials",
  ],
  Crime: [
    "Burglary",
    "Car Theft and Housebreaking Crime Index",
    "Crime Rate",
    "Homicides",
    "Kidnapping",
    "Rape",
    "Violence",
  ],
  Demographics: [
    "Age",
    "Fertility Indicators",
    "Gender",
    "Marital Status",
    "Migration",
    "Mortality Indicators",
    "Population",
    "Race",
  ],
  "Economic Activity": [
    "Agriculture",
    "Energy",
    "Establishments",
    "Firms",
    "Government",
    "Industries",
    "Industry and Construction",
    "Manufacturing",
    "Private Industries",
    "Research and Development",
    "Retail",
    "Trade",
    "Services",
    "Solar Electricity Generation",
    "Utilities",
    "Wholesale and Retail Trade",
    "Wholesale Trade",
  ],
  Economy: [
    "Balance of Payments",
    "Financial Markets",
    "Foreign-exchange",
    "Government Reserves",
    "Finance Inflation and Prices Infrastructure",
    "Labor",
    "National Accounts",
    "Official Development Assistance",
    "Short-Term Economic Indicators",
  ],
  Education: [
    "Education Indicators",
    "Education Level",
    "Education Type",
    "Educational Process Members",
  ],
  Energy: [
    "Electricity",
    "Energy Indicators",
    "Nuclear Electricity Plants",
    "Primary Energy",
    "Renewable electricity",
  ],
  Environment: [
    "Air Pollution",
    "Biodiversity",
    "Climate Change",
    "Emissions",
    "Land Pollution",
    "Waste Collected",
    "Water Pollution",
  ],
  "Financial Statements": [
    "Balance Sheet",
    "Cash Flow Statement",
    "Income Statement",
  ],
  "Foreign Trade": ["Exports", "Imports", "Trade"],
  "Goods and Services": ["Goods", "Goods and Services Indicators", "Services"],
  Health: [
    "Communicable Diseases",
    "Health Care Resources",
    "Health Indicators",
    "Health Status",
    "Non-communicable Diseases",
    "Risk factors",
  ],
  Institutions: [
    "Claims",
    "Corporations",
    "General",
    "Government",
    "Households",
    "Private",
    "Residence",
  ],
  "Land Use": ["Area", "Land Use", "Type"],
  Pets: ["Birds", "Cats", "Dogs", "Fish", "Population"],
  "Poverty and Equity": [
    "Income Inequality",
    "Number of Poor",
    "Poverty Gap",
    "Poverty Rate",
    "Quality of Life",
  ],
  Sports: [
    "Baseball",
    "Basketball",
    "Cricket",
    "Football",
    "Hockey",
    "Soccer",
    "Sports Indicators",
  ],
  Telecommunication: ["Internet", "Phone Subscriptions"],
  Tourism: [
    "Domestic Tourism",
    "Inbound Tourism",
    "International Tourism",
    "Outbound Tourism",
    "Tourism Indicators",
  ],
  Water: [
    "Dam Capacity",
    "Drinking water",
    "Sanitation Usage",
    "Water Supplies",
  ],
  "World Rankings": [
    "Civil Liabilities",
    "Corruption Perceptions Index",
    "Democracy Index",
    "Ease of Doing Business",
    "Forbes Rank",
    "Freedom",
    "Global Competitiveness Index",
    "Global Peace Index",
    "Human Development Index",
    "International Property Rights Index",
    "Knowledge Economy Index",
    "Legatum Prosperity Index",
    "Millward Brown",
    "Political Rights",
    "Press Freedom Index",
  ],
};
