import React from "react";

import { DashboardHeader } from "../../../components/Headers";
import ProfileFormPage from "../../../components/ProfileForm";
import PasswordFormPage from "../../../components/PasswordForm";
import PlanPage from "../Plan";

const ProfileUpdatePage = () => {
  return (
    <section>
      <DashboardHeader title="Datalabs" className="sticky top-0" />
      <section className="py-6">
        <div>
          <ProfileFormPage />
          <PasswordFormPage />
          <PlanPage />
        </div>
      </section>
    </section>
  );
};

export default ProfileUpdatePage;
