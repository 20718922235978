import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { H5, TextLink, P } from "./Texts";
import jsonIcon from "../assets/JSON.svg";
import csvIcon from "../assets/CSV.svg";
import closeIcon from "../assets/closeIcon.svg";
import arrowDarkDown from "../assets/downArrow.svg";
import errorIcon from "../assets/Error Icon.svg";
import successIcon from "../assets/successIcon.svg";
import briefcaseIcon from "../assets/briefcase.svg";
import educationIcon from "../assets/education_cap.svg";

export const SuggestionCard = styled.div`
  // padding: ${(props) => props.padding || "24px 68px"};
  border: 1px solid #f5f4fa;
  border-radius: 4px;

  min-height: ${(props) => props.h || "135px"};
  display: flex;
  flex-direction: column;
  justify-content: center;

  img.small {
    width: 54px;
    height: 36px;
  }

  &:hover {
    background: #f8f6fe;
  }
`;

export const TopicCardStyle = styled.button`
  background: ${(props) => props.bg || "#FFFFFF"};
  border-radius: 4px;
  border: ${(props) =>
    (props.selected && props.selected === true
      ? "2px solid #442ECF"
      : "2px solid #E7E5F2") || props.border};
  transition: 0.3s ease-in;

  &:hover {
    border: ${(props) => props.hoverBorder || "2px solid #442ECF"};
  }

  &:focus {
    outline: none;
    border: 2px solid #442ecf;
  }
`;

export const FilesHolder = styled.div`
  background: ${(props) => props.bg || "#FFFFFF"};
  border-radius: 4px;
`;

export const FileCardStyle = styled.div`
  background: #ffffff;
  border: ${(props) =>
    props.selected ? "2px solid #442ECF" : "2px solid #E7E5F2"};
  border-radius: 5px;
  height: 120px;
  width: 130px;

  .delete {
    background: #f5f4fa;
    top: -12px;
    right: -13px;
    font-size: 24px;
    border: 1px solid transparent;

    &:focus {
      outline: none;
      border: 1px solid #442ecf;
    }
  }
`;

export const FileCard = ({ deleteFile, selectFile, selected, name, file }) => {
  // const { name } = file;

  return (
    <div className="px-3">
      <FileCardStyle className="relative" selected={selected}>
        {selected && (
          <button
            className=" delete absolute justify-center items-center flex w-6 h-6 rounded-full"
            onClick={() => deleteFile(file)}
          >
            <img src={closeIcon} alt="x" className="w-5 h-5" />
          </button>
        )}
        <div
          className=" cursor-pointer flex flex-col justify-center items-center h-full"
          onClick={() => selectFile(file)}
        >
          <img
            src={name.includes(".csv") ? csvIcon : jsonIcon}
            alt=""
            className="mb-2 mx-auto"
            style={{
              width: "51px",
              height: "55px",
            }}
          />

          <TextLink
            color="#0D024E"
            fontSize="12px"
            style={{
              maxWidth: "110px",
            }}
            className="text-center break-words truncate"
          >
            {name}
          </TextLink>
        </div>
      </FileCardStyle>
    </div>
  );
};

export const TopicCard = ({
  img,
  title,
  subTitle,
  button,
  className,
  selected,
  topic,
  border,
  onClick,
}) => {
  return (
    <TopicCardStyle
      className={`p-6 w-full text-left flex items-center cursor-pointer ${className}`}
      selected={selected}
      border={border}
      onClick={() => onClick(topic)}
    >
      {img && (
        <div
          className="p-3 rounded-full mr-6"
          style={{
            background: "#442ECF05",
          }}
        >
          <img src={img} alt="" className="w-6 h-6" />
        </div>
      )}
      <div>
        <H5 color="#22202D">{title}</H5>
        <TextLink color="#716E8B" className="mt-2">
          {subTitle}
        </TextLink>
      </div>
    </TopicCardStyle>
  );
};

export const PreviewItemStyle = styled.div`
  background: #ffffff;
  border: 1px solid #e4e3ee;
  border-radius: 4px;

  img {
    transition: 0.3s ease;

    &.expanded {
      transform: rotate(180deg);
    }
  }
`;

export const PreviewItem = ({
  _key,
  title,
  type,
  description,
  notEmpty,
  required,
}) => {
  // const {
  //   key,
  //   title,
  //   type,
  //   description,
  //   notEmpty,
  //   required
  // } = fields;

  const [expanded, setExpanded] = useState(false);

  return (
    <PreviewItemStyle
      className="p-6 mb-6"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="flex items-center justify-between cursor-pointer">
        <H5 color="#9490B1" fontFamily="regular">
          <span
            style={{
              color: "#0D024E",
              marginRight: "4px",
            }}
          >
            {title}
          </span>
          ({type})
        </H5>
        <img
          src={arrowDarkDown}
          alt=""
          className={expanded ? "expanded" : ""}
        />
      </div>

      {expanded && (
        <>
          <H5 color="#0D024E" fontFamily="regular" className="my-6">
            {description}
          </H5>

          {_key && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Key
              </span>
              ({_key})
            </P>
          )}

          {type && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Type
              </span>
              ({type})
            </P>
          )}

          {notEmpty && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Not Empty
              </span>
              ({notEmpty})
            </P>
          )}

          <P color="#0D024E" fontFamily="regular">
            <span
              style={{
                color: "#9490B1",
                marginRight: "15px",
              }}
            >
              Required
            </span>
            {required ? "Yes" : "No"}
          </P>
        </>
      )}
    </PreviewItemStyle>
  );
};

export const PreviewData = ({ fields, name }) => {
  const { key, type, description, notEmpty, required } = fields;

  const [expanded, setExpanded] = useState(false);

  return (
    <PreviewItemStyle
      className="p-6 mb-6"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="flex items-center justify-between cursor-pointer">
        <H5 color="#9490B1" fontFamily="regular">
          <span
            style={{
              color: "#0D024E",
              marginRight: "4px",
            }}
          >
            {name}
          </span>
          ({type})
        </H5>
        <img
          src={arrowDarkDown}
          alt=""
          className={expanded ? "expanded" : ""}
        />
      </div>

      {expanded && (
        <>
          <H5 color="#0D024E" fontFamily="regular" className="my-6">
            {description}
          </H5>

          {key && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Key
              </span>
              ({key})
            </P>
          )}

          {type && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Type
              </span>
              ({type})
            </P>
          )}

          {notEmpty && (
            <P color="#0D024E" fontFamily="regular">
              <span
                style={{
                  color: "#B4B0D1",
                  marginRight: "15px",
                }}
              >
                Not Empty
              </span>
              ({notEmpty})
            </P>
          )}

          <P color="#0D024E" fontFamily="regular">
            <span
              style={{
                color: "#9490B1",
                marginRight: "15px",
              }}
            >
              Required
            </span>
            {required ? "Yes" : "No"}
          </P>
        </>
      )}
    </PreviewItemStyle>
  );
};

export const NotificationBarStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.success ? "#3BC97E" : "#E14170")};
`;

export const NotificationBar = (props) => (
  <NotificationBarStyle
    className="absolute top-0 left-0 py-3 w-full"
    success={props.success}
  >
    <img
      src={props.success ? successIcon : errorIcon}
      alt=""
      className="mr-4"
    />
    <P color={props.color || "#FFFFFF"}>{props.message}</P>
  </NotificationBarStyle>
);

export const DataResultCardStyle = styled.div`
  background: #ffffff;
  box-shadow: ${(props) => (props.shadowless ? "" : "0px 2px 2px #0000000D")};
  border: ${(props) => (props.borderless ? "" : "1px solid #F8F7FA")};
  border-radius: 4px;
`;

export const DataResultCard = ({
  // type,
  name,
  description,
  version,
  resources,
  updatedAt,
  type,
  shadowless,
}) => {
  // const {
  //   name,
  //   description,
  //   rows,
  //   source,
  //   updated,
  //   type
  // } = entry;
  // const [image, setImage] = useState(briefcaseIcon);
  let image;

  switch (type) {
    case "business":
      image = briefcaseIcon;
      break;

    case "edu":
      image = educationIcon;
      break;

    default:
      break;
  }
  return (
    <DataResultCardStyle shadowless={shadowless}>
      <div
        className="flex items-center px-4 py-6"
        style={{
          borderBottom: "1px solid #F8F7FA",
        }}
      >
        <div
          className="p-3 rounded-full mr-2 flex-shrink-0"
          style={{
            background: "#442ECF05",
          }}
        >
          <img src={image} alt="" className="w-6 h-6" />
        </div>
        <div className="w-10/12">
          <H5 color="#22202D" className="truncate capitalize">
            {name}
          </H5>
          <TextLink color="#22202D" className="mt-1 truncate" fontSize="12px">
            {description}
          </TextLink>
        </div>
      </div>
      <div className="flex items-start justify-between p-5">
        <div className="text-center">
          <TextLink color="#9998B1" fontSize="12px">
            Version
          </TextLink>
          <TextLink color="#22202D" className="mt-1 truncate" fontSize="12px">
            {version}
          </TextLink>
        </div>
        <div className="text-center">
          <TextLink color="#9998B1" fontSize="12px">
            Source
          </TextLink>
          <TextLink color="#22202D" className="mt-1 truncate" fontSize="12px">
            {resources.length}
          </TextLink>
        </div>
        <div className="text-center">
          <TextLink color="#9998B1" fontSize="12px">
            Updated
          </TextLink>
          <TextLink color="#22202D" className="mt-1 truncate" fontSize="12px">
            {moment(updatedAt).fromNow()}
          </TextLink>
        </div>
      </div>
    </DataResultCardStyle>
  );
};

export const SpecificDataCardStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px #0000000d;
  border: 1px solid #f5f4fa;
  border-radius: 4px;
`;

export const ShadowCard = styled.div`
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.shadowless ? "none" : "0px 2px 2px #0000000D"};
  border: ${(props) => (props.borderless ? "none" : "1px solid #F5F4FA")};
`;
