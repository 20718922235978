import { gql } from "@apollo/client";

export const UPDATE_USER_PLAN_MUTATION = gql`
  mutation UpdateUserPlan($plan: String!) {
    updateUserPlan(plan: $plan) {
      ... on UserPlanResponse {
        message
      }
    }
  }
`;
