import { base_url } from "./api";
import jwt_decode from "jwt-decode";

// TOAST FUNCTIONS
import { toast } from "react-toastify";
export const toastError = (errorMessage) => {
  return toast.error(errorMessage, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastSuccess = (message) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastWarning = (message) => {
  return toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const user = JSON.parse(localStorage.getItem("ssn_user"));

export const request = async (method, route, data) => {
  let requestOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = JSON.stringify(data);
  }

  let endpoint = `${base_url}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};

export const authorizedRequest = async (
  method,
  route,
  data,
  token = user?.token,
) => {
  let requestOptions = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = JSON.stringify(data);
  }

  let endpoint = `${base_url}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};

export const requestFormData = async (
  method,
  route,
  data,
  token = user?.token,
) => {
  let requestOptions = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };
  if (method.toLowerCase() !== "GET".toLowerCase()) {
    requestOptions.body = data;
  }

  let endpoint = `${base_url}${route}`;

  if (route.includes("https://")) {
    endpoint = route;
  }
  const response = await fetch(endpoint, requestOptions);
  return await response.json();
};

export const decodeToken = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("ssn_user"));
  const token = loggedInUser?.token;
  if (token === undefined) {
    return false;
  }
  let decoded = jwt_decode(token);
  const { exp } = decoded;
  const date = new Date();
  if (date > exp * 1000) {
    return false;
  }
  return true;
};
