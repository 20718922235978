import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Drawer from "../../components/Drawer";
import { P, H1, H4 } from "../../components/Texts";
import { DataLabsHeader } from "../../components/Headers";
import Footer from "../../components/LandingPageFooter";
import { decodeToken } from "../../lib/utils";
import { authContext } from "../../store/AuthStore";

const Hero = styled.section`
  background: #ffffff;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const TermsPage = () => {
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  return (
    <main>
      <DataLabsHeader isValid={isValid} />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="lg:mt-56">
        <div className="container-flexible pt-10 lg:pt-10 lg:w-1/2 lg:mb-20">
          <H1
            color="#442ED0"
            className="mb-10 lg:w-5/6 font-bold"
            mediumSize="56px"
            smallSize="32px"
          >
            Term of Service
          </H1>
        </div>
      </Hero>

      <div className="container-flexible w-10/12 mb-20 ">
        <H4 color="#0C024D" fontFamily="semi" className="mb-4" fontSize="18px">
          1. Acceptance of Terms
        </H4>
        <P>
          1.1. Philanthrolab (“Company” or “we”) provides the Datalab Service
          (as defined below) to you through its web site and platform located at
          https://datalabs.socialsafety.net (the “Site”), subject to this Terms
          of Service agreement (“TOS”). By accepting this TOS or by accessing or
          using the Service or Site, you acknowledge that you have read,
          understood, and agree to be bound by this TOS. If you are entering
          into this TOS on behalf of a company, business or other legal entity,
          you represent that you have the authority to bind such entity and its
          affiliates to this TOS, in which case the terms “you” or “your” shall
          refer to such entity and its affiliates. If you do not have such
          authority, or if you do not agree with this TOS, you must not accept
          this TOS and may not use the Service.
        </P>
        <P className="mt-4">
          1.2. Company may change this TOS from time to time by providing thirty
          (30) days prior notice either by emailing the email address associated
          with your account or by posting a notice on the Site. You can review
          the most current version of this TOS at any time at
          https://datalabs.socialsafety.net/terms. The revised terms and
          conditions will become effective thirty (30) days after we post or
          send you notice of such changes, and if you use the Service after that
          date, your use will constitute acceptance of the revised terms and
          conditions. If any change to this TOS is not acceptable to you, your
          only remedy is stop using the Services and send a cancellation email
          to contact@philanthrolab.co.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          2. Description of Service
        </H4>
        <P>
          The “Service” includes (a) the Site, (b) Company’s cloud platform for
          hosting, sharing and processing of data, and © all software (including
          the Software, as defined below), data, reports, text, images, sounds,
          video, and content made available through any of the foregoing
          (collectively referred to as the “Content”). Any new features added to
          or augmenting the Service are also subject to this TOS.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          3. General Conditions / Access and Use of the Service
        </H4>
        <P>
          3.1. Subject to the terms and conditions of this TOS, you may access
          and use the Service only for lawful purposes. All rights, title and
          interest in and to the Service and its components will remain with and
          belong exclusively to Company. You shall not (a) sublicense, resell,
          rent, lease, transfer, assign, time share or otherwise commercially
          exploit or make the Service available to any third party; (b) use the
          Service in any unlawful manner (including without limitation in
          violation of any data, privacy or export control laws) or in any
          manner that interferes with or disrupts the integrity or performance
          of the Service or its components, or © modify, adapt or hack the
          Service to, or otherwise attempt to gain unauthorized access to the
          Service or its related systems or networks. You shall comply with any
          codes of conduct, policies or other notices Company provides you or
          publishes in connection with the Service, and you shall promptly
          notify Company if you learn of a security breach related to the
          Service. Without limiting the foregoing, you acknowledge that Company
          may establish general practices and limits concerning use of the
          Service, including without limitation the maximum period of time that
          data, code or other content will be retained by the Service and the
          maximum storage space that will be allotted on Company’s servers on
          your behalf. You agree that Company has no responsibility or liability
          for the deletion or failure to store any data or other content
          maintained or uploaded by the Service. You acknowledge that Company
          reserves the right to terminate accounts that are inactive for an
          extended period of time. You further acknowledge that Company reserves
          the right to change these general practices and limits at any time, in
          its sole discretion.
        </P>
        <P className="mt-4">
          3.2. Any software, APIs, tools or the like that may be made available
          by Company in connection with the Service (“Software”) contains
          proprietary and confidential information that is protected by
          applicable intellectual property and other laws. Subject to the terms
          and conditions of this TOS, Company hereby grants you a
          non-transferable, non-sublicensable and non-exclusive right and
          license to use the object code of any Software on a single device
          solely in connection with the Service, provided that you shall not
          (and shall not allow any third party to) copy, modify, create a
          derivative work of, reverse engineer, reverse assemble or otherwise
          attempt to discover any source code or sell, assign, sublicense or
          otherwise transfer any right in any Software. You agree not to access
          the Service by any means other than through the interface that is
          provided by Company for use in accessing the Service. Any rights not
          expressly granted herein are reserved and no license or right to use
          any trademark of Company or any third party is granted to you in
          connection with the Service.
        </P>
        <P className="mt-4">
          3.3. You are solely responsible for all software, code, data,
          information, feedback, suggestions, text, content and other materials
          that you upload, post, deliver, provide or otherwise transmit or store
          (hereafter “post(ing)”) in connection with or relating to the Service
          (“Your Content”). You are responsible for maintaining the
          confidentiality of your login, password and account and for all
          activities that occur under your login or account. Company reserves
          the right to access your account in order to respond to your requests
          for technical support. By posting Your Content on or through the
          Service, you hereby do and shall grant Company a worldwide,
          non-exclusive, royalty-free, fully paid, sublicensable and
          transferable license to use, modify, reproduce, distribute, display,
          publish and perform Your Content only in connection with its provision
          of the Service. Important Note: if you are a user of our “Basic” tier
          any Content that you upload to the Service will be automatically
          publicly available to the general public. If you do not want to make
          your Content publicly available, you must subscribe to our premium
          tiers. Company has the right, but not the obligation, to monitor the
          Service, Content, or Your Content. You further agree that Company may
          remove or disable any Content at any time for any reason (including,
          but not limited to, upon receipt of claims or allegations from third
          parties or authorities relating to such Content), or for no reason at
          all.
        </P>
        <P className="mt-4">
          3.4. You understand that the operation of the Service, including Your
          Content, may be unencrypted and involve (a) transmissions over various
          networks; (b) changes to conform and adapt to technical requirements
          of connecting networks or devices and © transmission to Company’s
          third party vendors and hosting partners to provide the necessary
          hardware, software, networking, storage, and related technology
          required to operate and maintain the Service. Accordingly, you
          acknowledge that you bear sole responsibility for adequate security,
          protection and backup of Your Content. Company will have no liability
          to you for any unauthorized access or use of any of Your Content, or
          any corruption, deletion, destruction or loss of any of Your Content.
        </P>
        <P className="mt-4">
          3.5. You shall be responsible for obtaining and maintaining any
          equipment and ancillary services needed to connect to, access or
          otherwise use the Services, including, without limitation, modems,
          hardware, server, software, operating system, networking, web servers,
          long distance and local telephone service (collectively, “Equipment”).
          You shall be responsible for ensuring that such Equipment is
          compatible with the Services (and, to the extent applicable, the
          Software) and complies with all configurations and specifications set
          forth in Company’s published policies then in effect. You shall also
          be responsible for maintaining the security of the Equipment, your
          Account, passwords (including but not limited to administrative and
          user passwords) and files, and for all uses of your Account or the
          Equipment with or without your knowledge or consent.
        </P>
        <P className="mt-4">
          3.6. The failure of Company to exercise or enforce any right or
          provision of this TOS shall not be a waiver of that right. You
          acknowledge that this TOS is a contract between you and Company, even
          though it is electronic and is not physically signed by you and
          Company, and it governs your use of the Service.
        </P>
        <P className="mt-4">
          3.7. Company reserves the right to use your name and/or company name
          as a reference for marketing or promotional purposes on Company’s
          website and in other communication with existing or potential Company
          customers. To decline Company this right you need to email
          contact@philanthrolab.co stating that you do not wish to be used as a
          reference.
        </P>
        <P className="mt-4">
          3.8. Subject to the terms hereof, Company may (but has no obligation
          to) provide technical support services, through email in accordance
          with our standard practice.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          4. Payment
        </H4>
        <P>
          To the extent the Service or any portion thereof is made available for
          any fee, you will be required to select a payment plan and provide
          Company information regarding your credit card or other payment
          instrument. You represent and warrant to Company that such information
          is true and that you are authorized to use the payment instrument. You
          will promptly update your account information with any changes (for
          example, a change in your billing address or credit card expiration
          date) that may occur. You agree to pay Company the amount that is
          specified in the payment plan in accordance with the terms of such
          plan and this TOS. You hereby authorize Company to bill your payment
          instrument in advance on a periodic basis in accordance with the terms
          of the applicable payment plan until you terminate your account, and
          you further agree to pay any charges so incurred. If you dispute any
          charges you must let Company know within sixty (60) days after the
          date that Company invoices you. We reserve the right to change
          Company’s prices. If Company does change prices, Company will provide
          notice of the change on the Site or in email to you, at Company’s
          option, at least 30 days before the change is to take effect. Your
          continued use of the Service after the price change becomes effective
          constitutes your agreement to pay the changed amount. Company may
          choose to bill through an invoice, in which case, full payment for
          invoices issued in any given month must be received by Company thirty
          (30) days after the mailing date of the invoice, or the Services may
          be terminated. Unpaid invoices are subject to a finance charge of 1.5%
          per month on any outstanding balance, or the maximum permitted by law,
          whichever is lower, plus all expenses of collection. You shall be
          responsible for all taxes associated with Services other than U.S.
          taxes based on Company’s net income.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          5. Representations and Warranties
        </H4>
        <P>
          You represent and warrant to Company that (i) you have full power and
          authority to enter into this TOS; (ii) you own all Your Content or
          have obtained all permissions, releases, rights or licenses required
          to engage in your posting and other activities (and allow Company to
          perform its obligations) in connection with the Services without
          obtaining any further releases or consents; (iii) Your Content and
          other activities in connection with the Service, and Company’s
          exercise of all rights and license granted by you herein, do not and
          will not violate, infringe, or misappropriate any third party’s
          copyright, trademark, right of privacy or publicity, or other personal
          or proprietary right, nor does Your Content contain any matter that is
          defamatory, obscene, unlawful, threatening, abusive, tortious,
          offensive or harassing; and (iv) you are eighteen (18) years of age or
          older.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          6. Termination
        </H4>
        <P>
          You have the right to terminate your account at any time by sending a
          cancellation request to contact@philanthrolab.co Subject to earlier
          termination as provided below, Company may terminate your Account and
          this TOS at any time by providing thirty (30) days prior notice to the
          administrative email address associated with your Account. In addition
          to any other remedies we may have, Company may also terminate this TOS
          upon ten (10) days’ notice (or two (2) days in the case of
          nonpayment), if you breach any of the terms or conditions of this TOS.
          Company reserves the right to modify or discontinue, temporarily or
          permanently, the Service (or any part thereof). All of Your Content on
          the Service (if any) may be permanently deleted by Company upon any
          termination of your account in its sole discretion. If Company
          terminates your account without cause and you have signed up for a
          fee-bearing service, Company will refund the pro-rated, unearned
          portion of any amount that you have prepaid to Company for such
          Service. However, all accrued rights to payment and the terms of
          Section 4-12 shall survive termination of this TOS.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          7. Disclaimer of Warranties
        </H4>
        <P>
          The Services may be temporarily unavailable for scheduled maintenance
          or for unscheduled emergency maintenance, either by Company or by
          third-party providers, or because of other causes beyond our
          reasonable control, but Company shall use reasonable efforts to
          provide advance notice in writing or by email of any scheduled service
          disruption. HOWEVER, THE SERVICE, INCLUDING THE SITE AND CONTENT, AND
          ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND COMPANY
          EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-
          INFRINGEMENT. YOU ACKNOWLEDGE THAT COMPANY DOES NOT WARRANT THAT THE
          SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
          VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY
          BE OBTAINED FROM USE OF THE SERVICES, AND NO INFORMATION, ADVICE OR
          SERVICES OBTAINED BY YOU FROM COMPANY OR THROUGH THE SERVICE SHALL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS Terms of Service.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          8. Limitation of Liability
        </H4>
        <P>
          8.1. UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
          CONTRACT, TORT, OR OTHERWISE) SHALL COMPANY BE LIABLE TO YOU OR ANY
          THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES
          OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS, LOSSES
          OR LIABILITIES IN EXCESS OF THE FEES ACTUALLY PAID BY YOU IN THE SIX
          (6) MONTHS PRECEDING THE EVENT GIVING RISE TO YOUR CLAIM OR, IF NO
          FEES APPLY, ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS
          SECTION ALLOCATE THE RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE
          PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO
          ENTER INTO THIS Terms of Service.
        </P>
        <P className="mt-4">
          8.2. Some states do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which
          means that some of the above limitations may not apply to you. IN
          THESE STATES, COMPANY’S LIABILITY WILL BE LIMITED TO THE GREATEST
          EXTENT PERMITTED BY LAW.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          9. Indemnification
        </H4>
        <P>
          You shall defend, indemnify, and hold harmless Company from and
          against any claims, actions or demands, including without limitation
          reasonable legal and accounting fees, arising or resulting from your
          breach of this TOS, any of Your Content, or your other access,
          contribution to, use or misuse of the Service. Company shall provide
          notice to you of any such claim, suit or demand. Company reserves the
          right to assume the exclusive defense and control of any matter which
          is subject to indemnification under this section. In such case, you
          agree to cooperate with any reasonable requests assisting Company’s
          defense of such matter.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          10. Assignment
        </H4>
        <P>
          You may not assign this TOS without the prior written consent of
          Company, but Company may assign or transfer this TOS, in whole or in
          part, without restriction.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          11. Miscellaneous
        </H4>
        <P>
          If any provision of this TOS is found to be unenforceable or invalid,
          that provision will be limited or eliminated to the minimum extent
          necessary so that this TOS will otherwise remain in full force and
          effect and enforceable. Both parties agree that this TOS is the
          complete and exclusive statement of the mutual understanding of the
          parties and supersedes and cancels all previous written and oral
          agreements, communications and other understandings relating to the
          subject matter of this TOS, and that all waivers and modifications
          must be in a writing signed by both parties, except as otherwise
          provided herein. No agency, partnership, joint venture, or employment
          is created as a result of this TOS and you do not have any authority
          of any kind to bind Company in any respect whatsoever. In any action
          or proceeding to enforce rights under this TOS, the prevailing party
          will be entitled to recover costs and attorneys’ fees. All notices
          under this TOS will be in writing and will be deemed to have been duly
          given when received, if personally delivered; when receipt is
          electronically confirmed, if transmitted by facsimile or email; the
          day after it is sent, if sent for next day delivery by recognized
          overnight delivery service; and upon receipt, if sent by certified or
          registered mail, return receipt requested.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          12. Dispute Resolution
        </H4>
        <P>
          The parties will engage in good faith negotiations to resolve the
          dispute for a period of ten (10) business days after written notice of
          the dispute or issue is provided by one party to the other. Within
          such ten (10) business days, representatives from each party will
          engage in negotiations to resolve the dispute, and such individuals
          will meet in person, via videoconference or via telephone and attempt
          to informally resolve the dispute or issues. If those persons are
          unable to resolve the dispute within such ten (10) business days, then
          unless the parties have mutually agreed to extend the negotiation
          period, a party may exercise its rights available to such party under
          this agreement or otherwise.
        </P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          13. Governing law
        </H4>
        <P>This agreement is made under the laws of England and Wales.</P>
        <H4
          color="#0C024D"
          fontFamily="semi"
          className="mt-8 mb-4"
          fontSize="18px"
        >
          14. Privacy
        </H4>
        <P>
          Please visit our{" "}
          <Link to="privacy" className="text-purple-500">
            Privacy Policy
          </Link>{" "}
          to understand how the Company collects and uses personal information.
        </P>
      </div>

      <Footer />
    </main>
  );
};

export default TermsPage;
