import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation } from "@apollo/client";

import { PrimaryButton } from "./Buttons";
import { H3, P } from "./Texts";
import { DarkFormField } from "./Inputs";
import { UPDATE_PASSWORD_MUTATION } from "../shared/queries";
import { toastError, toastSuccess } from "../lib/utils";

const PasswordFormPage = () => {
  const [passwordType] = useState("password");

  const [updatePassword, { loading, data }] = useMutation(
    UPDATE_PASSWORD_MUTATION,
  );

  const onUpdatePassword = (data) => {
    updatePassword({
      variables: {
        password: data.oldpassword,
        newPassword: data.newpassword,
        confirmPassword: data.confirmpassword,
      },
    });
  };

  useEffect(() => {
    if (data) {
      switch (data.updatePassword.__typename) {
        case "MissingInputDetail":
          toastError(data.updatePassword.message);
          break;

        case "PasswordUpdateResponse":
          toastSuccess(data.updatePassword.message);
          break;

        default:
          return null;
      }
    }
  }, [data]);

  const { register, errors, handleSubmit, watch } = useForm({
    validateCriteriaMode: "all",
  });

  const newPassword = watch("newpassword");

  return (
    <section className="py-6">
      <div className="flex px-10 mt-2">
        <H3 color="#0C024D">Password</H3>
      </div>

      <div className="p-10 md:px-10 md:py-14">
        <form onSubmit={handleSubmit(onUpdatePassword)}>
          <div className="grid grid-cols-2 gap-6 w-2/3">
            <div>
              <DarkFormField
                type={passwordType}
                id="oldpassword"
                name="oldpassword"
                w="184px"
                ref={register({
                  required: "Password is required.",
                })}
                placeholder="Enter old password"
                label="Old Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="oldpassword"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>

            <div>
              <DarkFormField
                type={passwordType}
                id="newpassword"
                name="newpassword"
                w="384px"
                ref={register({
                  required: "New Password is required.",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
                placeholder="Enter new password"
                label="New Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="newpassword"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>
            <div>
              <DarkFormField
                type={passwordType}
                id="confirmpassword"
                name="confirmpassword"
                w="300px"
                ref={register({
                  required: "New Password is required.",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  validate: (value) =>
                    value === newPassword || "The passwords do not match",
                })}
                placeholder="Enter confirmed new password"
                label="Confirm New Password"
                className="w-full"
              />
              <ErrorMessage
                errors={errors}
                name="confirmpassword"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>
          </div>
          <div>
            <PrimaryButton className="" disabled={loading}>
              {loading ? "Updating" : "Save changes"}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default PasswordFormPage;
