import React, { useEffect, useContext } from "react";

import { PrimaryButton } from "../../components/Buttons";
import { H3, H4, P } from "../../components/Texts";
import { authContext } from "../../store/AuthStore";
import { toastError, toastSuccess } from "../../lib/utils";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_PLAN_MUTATION } from "../../shared/queries/plan.upgrade";

const PlanPage = () => {
  const { auth } = useContext(authContext);

  const [updateUserPlan, { data, loading }] = useMutation(
    UPDATE_USER_PLAN_MUTATION,
  );

  const onUpdateUserPlan = (plan) => {
    updateUserPlan({
      variables: {
        plan: plan,
      },
    });
  };

  useEffect(() => {
    if (data) {
      switch (data.updateUserPlan.__typename) {
        case "MissingInputDetail":
          toastError(data.updateUserPlan.message);
          break;

        case "UserPlanResponse":
          toastSuccess(data.updateUserPlan.message);
          auth.updateAuth({
            user: {
              ...auth.user,
              plan: "basic",
            },
          });
          break;

        default:
          return null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <section>
      <section className="py-6">
        <div className="flex px-10 mt-6">
          <H3 color="#0C024D">Plan</H3>
        </div>

        <div className="p-4 md:px-10 md:py-14">
          <div className="">
            <H4>
              You are currently on the{" "}
              <span style={{ color: "#442ECF" }}> {auth?.user?.plan}</span>{" "}
              plan.
            </H4>
          </div>
          {auth?.user?.plan !== "basic" && (
            <div className="">
              <P className="mb-10">Click on the button below to upgrade</P>
              <PrimaryButton
                disabled={loading}
                onClick={() => onUpdateUserPlan("basic")}
              >
                Upgrade
              </PrimaryButton>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default PlanPage;
