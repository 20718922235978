import { gql } from "@apollo/client";

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $password: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updatePassword(
      password: $password
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      ... on MissingInputDetail {
        message
      }
      ... on PasswordUpdateResponse {
        message
      }
    }
  }
`;
