import React, { useContext } from "react";
import styled from "styled-components";

import { themeContext } from "../lib/themeContext";
import Header from "./Headers";

const LayoutStyle = styled.main`
  background: ${(props) => props.theme.background};
`;

const Layout = (props) => {
  const { theme } = useContext(themeContext);

  return (
    <LayoutStyle theme={theme}>
      <Header />
      {props.children}
    </LayoutStyle>
  );
};

export default Layout;

export const ThemeLessLayout = styled.main`
  background: #0d024e;
  padding-top: 100px;
  padding-bottom: 100px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  min-height: 100vh;
`;

export const PublicLayout = styled.section`
  padding-left: 96px;
  padding-right: 96px;

  @media (max-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const DashboardLayout = styled.main`
  grid-template-columns: 1fr 17fr;
`;
