import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { decodeToken } from "./lib/utils";
import { AuthProvider } from "./store/AuthStore";

import { BrowserRouter as Router } from "react-router-dom";

// localStorage.removeItem("ssn_user");
const authLink = setContext((_, { headers }) => {
  // const isValid = decodeToken();
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  const loggedInUser = JSON.parse(localStorage.getItem("ssn_user"));
  const token = loggedInUser ? loggedInUser.token : null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: decodeToken(token) ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_BASE_URI}/graphql`,
});

const additiveLink = from([authLink, httpLink]);

const client = new ApolloClient({
  ssrMode: typeof window === "undefined",
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Datapackage: {
        keyFields: ["id"],
      },
      Query: {
        fields: {
          search: {
            keyArgs: ["text"],
            merge: (
              existing = { __typename: "Datapackages", data: [] },
              incoming,
            ) => {
              const data = [...existing.data, ...incoming.data].reduce(
                (array, current) => {
                  return array.map((i) => i.__ref).includes(current.__ref)
                    ? array
                    : [...array, current];
                },
                [],
              );
              return {
                ...incoming,
                data,
              };
            },
          },
        },
      },
    },
  }),
  link: additiveLink,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
