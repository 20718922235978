// new data reducer for datalake

export const newDataReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case "UPDATE":
      return { ...state, ...payload };

    case "DISCARD":
      window.location = "/dashboard/data";
      return {};

    case "PUBLISH":
      break;

    case "RETRIEVE":
      return state;

    default:
      return state;
  }
};
