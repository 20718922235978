import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import Drawer from "../../components/Drawer";
import { H1, H4, H5 } from "../../components/Texts";
import { DataLabsHeader } from "../../components/Headers";
import Footer from "../../components/LandingPageFooter";
import { decodeToken } from "../../lib/utils";
import { authContext } from "../../store/AuthStore";

const Hero = styled.section`
  background: #ffffff;

  @media (max-width: 1000px) {
    min-height: fit-content;
    max-height: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

const AboutUsPage = () => {
  const { auth } = useContext(authContext);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (auth && auth.token) {
      setIsValid(decodeToken(auth.token));
    }
  }, [auth]);

  return (
    <main>
      <DataLabsHeader isValid={isValid} />
      <div className="md:hidden">
        <Drawer />
      </div>
      <Hero className="lg:mt-56">
        <div className="container-flexible pt-10 lg:pt-10 lg:w-1/2 lg:mb-20">
          <H1
            color="#442ED0"
            className="mb-10 lg:w-5/6 font-bold"
            mediumSize="56px"
            smallSize="32px"
          >
            About
          </H1>
        </div>
      </Hero>

      <div className="container-flexible w-10/12 mb-20 ">
        <H4 color="#0C024D" fontFamily="semi" className="mb-4" fontSize="18px">
          Datalabs is a centralized data warehouse for finding, publishing and
          exploring open data. The overarching idea is that citizens,
          individuals, companies, and governments have access to open data as
          well as the required tools to make that data actionable.
        </H4>
        <H5
          color="#0C024D"
          fontFamily="medium"
          className="mt-8 mb-2"
          fontSize="16px"
        >
          - On Datalabs, you can:
        </H5>
        <ul className="pl-8" style={{ listStyleType: "disc" }}>
          <li className="mt-2">
            Search for data on various topics, categories using free text
          </li>
          <li className="mt-2">Perform client-side analysis on data</li>
          <li className="mt-2">Visualize and explore data</li>
          <li className="mt-2">Export data to various formats</li>
          <li className="mt-2">Upload and manage private/public data</li>
        </ul>
        <H5
          color="#0C024D"
          fontFamily="medium"
          className="mt-8 mb-2"
          fontSize="16px"
        >
          Datalabs was developed and is managed by{" "}
          <a href="https://www.philanthrolab.co">Philanthrolab.co</a> inquiries
          or complaints, send an email to{" "}
          <a href="mailto:contact@philanthrolab.co">
            contact@philanthrolab.co.
          </a>
        </H5>
      </div>

      <Footer />
    </main>
  );
};

export default AboutUsPage;
