import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import { CustomSwitch, RedirectToLostPage } from "../../shared/routing";

// import { themeContext } from '../lib/themeContext';
// import careIcon from "../../assets/care.svg";
// import educationIcon from "../../assets/education.svg";
// import insuranceIcon from "../../assets/insurance.svg";
// import healthIcon from "../../assets/health.svg";
// import foodIcon from "../../assets/food.svg";
// import locationIcon from "../../assets/location.svg";
// import searchSmallIcon from "../../assets/search-small.svg";

import EntriesPage from "./EntriesPage";
import NewEntry from "./NewEntry";

const DataPage = (props) => {
  let { path } = useRouteMatch();

  return (
    <CustomSwitch>
      <Route path={`${path}/new-entry`} component={NewEntry} />

      <Route exact path={`${path}`} component={EntriesPage} />

      <RedirectToLostPage />
    </CustomSwitch>
  );
};

export default DataPage;
