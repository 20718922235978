/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { gql, useQuery } from "@apollo/client";

import { DataLabsHeader } from "../../components/Headers";
import { Link, useHistory, useParams } from "react-router-dom";
import { DataResultCard } from "../../components/Cards";
import { H3, P } from "../../components/Texts";
import noResultImg from "../../assets/no-result-img.svg";
import searchIcon from "../../assets/search.svg";
import { PublicLayout } from "../../components/Layout";
import { SearchInputWidget } from "../../components/Inputs";
import { OutlineButton } from "../../components/Buttons";

const FETCH_RESULTS = gql`
  query GetSearchResults($query: String!, $page: Int!, $limit: Int!) {
    search(text: $query, page: $page, limit: $limit) {
      data {
        id
        name
        topic
        title
        description
        access
        attribution
        attribution_url
        version
        author
        resources {
          path
          pathType
          name
          format
          encoding
          hash
        }
        createdAt
        updatedAt
        updatedLastBy
      }
    }
  }
`;

const PublicSearchPage = (props) => {
  let history = useHistory();
  let { query } = useParams();
  const searchInputRef = useRef(null);

  const [page, setPage] = useState(0);

  // const [loading, setLoading] = useState(true);
  // const [results, setResults] = useState({});
  // const [pages, setPages] = useState(null);
  // const [lowerLimit, setLowerLimit] = useState(1);
  // const [upperLimit, setUpperLimit] = useState(9);
  // const [count, setCount] = useState(0);

  // const calcPages = (count, limit = 9) => {
  //   setPages(Math.floor(count / limit));
  // };

  const { loading, data, fetchMore } = useQuery(FETCH_RESULTS, {
    variables: { query, page, limit: 9 },
  });

  // useEffect(() => {
  //   if (data) {
  //     calcPages(data.search.result_count);
  //     setCount(data.search.result_count);
  //   }
  // }, [data]);

  useEffect(() => {
    // setLowerLimit((page + 1) * 9 - 8);
    // setUpperLimit((page + 1) * 9);
    fetchMore({
      variables: {
        page,
      },
    });
  }, [page]);

  const onSearch = () => {
    history.push(`/search/${searchInputRef.current.value}`);
  };

  const focusSearch = (e) => {
    searchInputRef.current.focus();
  };

  const { register, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  useEffect(() => {
    if (searchInputRef.current) {
      register(searchInputRef.current);
    }
  }, []);

  const [first, ...rest] = query;

  return (
    <PublicLayout className="max-h-screen overflow-y-auto mt-24">
      <DataLabsHeader />
      <div
        className="flex items-center mb-2 pt-10 w-11/12"
        style={{
          width: "fit-content",
        }}
      >
        <P className="ml-1">
          Showing results for
          <span
            style={{
              color: "442ED0",
            }}
            className="ml-1 font-semibold capitalize"
          >
            {`${first.toUpperCase()}${rest.join("")}`}
          </span>
        </P>
      </div>
      <section className="pt-5 pb-10 ">
        {!loading ? (
          <>
            {data?.search.data.length > 0 ? (
              <>
                <div className="grid md:grid-cols-3 grid-flow-row gap-6 mb-6">
                  {data?.search.data.map((result, idx) => (
                    <Link key={idx} to={`/search/details/${result.id}`}>
                      <DataResultCard
                        key={idx}
                        type={"business"}
                        {...result}
                        // entry={entry}
                      />
                    </Link>
                  ))}
                </div>
                <div className="mt-10 flex items-center">
                  <div className="flex justify-between items-center">
                    <OutlineButton
                      color="#22202D"
                      className="block ml-3 px-4 py-2 border text-sm font-medium rounded-md"
                      onClick={() => setPage(page + 1)}
                    >
                      Load more
                    </OutlineButton>
                  </div>
                </div>
                {/* <div className="mt-10 flex justify-between items-center">
                  <P>
                    Showing <span className="font-bold">{lowerLimit} </span>
                    to{" "}
                    <span className="font-bold">
                      {upperLimit > count ? count : upperLimit}
                    </span>{" "}
                    of
                    <span className="font-bold"> {count}</span>
                  </P>
                  <div class="flex justify-between items-center">
                    {page !== 0 && (
                      <OutlineButton
                        color="#22202D"
                        className="block px-4 py-2 border text-sm font-medium rounded-md"
                        onClick={() => setPage(page - 1)}
                      >
                        Previous
                      </OutlineButton>
                    )} */}
              </>
            ) : (
              <div className="pt-10 flex flex-col items-center">
                <img src={noResultImg} alt="" className="" />
                <H3 color="#22202D" className="my-10">
                  No results found
                </H3>
                {/* <P fontSize="16px" color="" className="mt-2 mb-10 text-center">
                  Try another phrase.
                </P> */}
                <form onSubmit={handleSubmit(onSearch)} className="w-2/5">
                  <SearchInputWidget
                    type="search"
                    id="searchQuery"
                    name="searchQuery"
                    iconBefore={searchIcon}
                    // inputClassName="h-10"
                    containerOnClick={focusSearch}
                    ref={(e) => {
                      searchInputRef.current = e;
                      register(searchInputRef, {
                        required: "Enter another phrase.",
                      });
                    }}
                    placeholder="Enter another phrase"
                  />
                </form>
              </div>
            )}
          </>
        ) : (
          <div className="grid md:grid-cols-3 grid-flow-row gap-6 mb-6">
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
            <div className="mt-10 mb-8">
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 h-10 w-full mb-4"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5 mb-2"></div>
              <div className="animate-pulse rounded-md bg-gray-600 opacity-75 w-full h-5"></div>
            </div>
          </div>
        )}
      </section>
    </PublicLayout>
  );
};

export default PublicSearchPage;
