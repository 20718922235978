import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      ... on UserNotFound {
        message
      }
      ... on UpdatedUserResponse {
        id
        firstName
        lastName
        email
        country
        role
      }
      ... on UpdateUserError {
        message
      }
    }
  }
`;
