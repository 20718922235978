import React, { useState, useEffect, useContext } from "react";

import { DispatchCtx } from "../../store/Store";
import SelectTopicStep from "./SelectTopic";
import DescriptionStep from "./Description";
import PublishStep from "./VIsibility";
import UploadDataStep from "./UploadData";
import PreviewImports from "./ImportPreview";

const NewEntry = (props) => {
  const dispatch = useContext(DispatchCtx);
  const [step, setStep] = useState("upload-data");

  useEffect(() => {
    retrieveNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNewData = (data) => {
    return dispatch({ type: "UPDATE", payload: data });
  };

  const discardNewData = (data) => {
    return dispatch({ type: "DISCARD", payload: data });
  };

  const retrieveNewData = () => {
    dispatch({ type: "RETRIEVE", payload: "" });
    return;
  };

  const changeStep = (step) => setStep(step);

  return (
    <React.Fragment>
      {step === "select-topic" && (
        <SelectTopicStep
          changeStep={(step) => changeStep(step)}
          updateNewData={(data) => updateNewData(data)}
          discardNewData={(data) => discardNewData(data)}
          retrieveNewData={retrieveNewData}
        />
      )}

      {step === "description" && (
        <DescriptionStep
          changeStep={(step) => changeStep(step)}
          updateNewData={(data) => updateNewData(data)}
          discardNewData={(data) => discardNewData(data)}
          retrieveNewData={retrieveNewData}
        />
      )}

      {step === "publish" && (
        <PublishStep
          changeStep={(step) => changeStep(step)}
          updateNewData={(data) => updateNewData(data)}
          discardNewData={(data) => discardNewData(data)}
          retrieveNewData={retrieveNewData}
        />
      )}

      {step === "upload-data" && (
        <UploadDataStep
          changeStep={(step) => changeStep(step)}
          updateNewData={(data) => updateNewData(data)}
          discardNewData={(data) => discardNewData(data)}
          retrieveNewData={retrieveNewData}
        />
      )}

      {step === "preview-files" && (
        <PreviewImports
          changeStep={(step) => changeStep(step)}
          updateNewData={(data) => updateNewData(data)}
          discardNewData={(data) => discardNewData(data)}
          retrieveNewData={retrieveNewData}
        />
      )}
    </React.Fragment>
  );
};

export default NewEntry;
