import React from "react";
import styled from "styled-components";
import { P } from "./Texts";

const LoaderStyle = styled.div`
  background: #f5f4fa;
  border-radius: 4px;
  height: ${(props) => props.h || "8px"};
  width: 100%;

  div {
    width: ${(props) => props.w}%;
    height: 100%;
    background: #442ecf;
    border-radius: 4px;
    transition: 0.3s width ease;
  }
`;

const Loader = ({ h, w }) => {
  return (
    <>
      <LoaderStyle h={h} w={w} className="mb-4">
        <div></div>
      </LoaderStyle>
      <P color="#0D024E" fontSize="14px" className="text-center">
        {w}%
      </P>
    </>
  );
};

Loader.defaultProps = {
  w: 0,
};

export default Loader;
