import React, { useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";

import { authContext } from "../store/AuthStore";

import { PrimaryButton } from "./Buttons";
import { H3, P } from "./Texts";
import { DarkFormField, DarkSelectInput } from "./Inputs";
import { UPDATE_USER_MUTATION } from "../shared/queries";
import { toastError, toastSuccess } from "../lib/utils";
import countries from "../lib/countries";

const ProfileFormPage = () => {
  const { auth } = useContext(authContext);

  const [updateUser, { loading, data }] = useMutation(UPDATE_USER_MUTATION);

  const onUpdateUser = (data) => {
    updateUser({
      variables: {
        data: {
          firstName: data.firstname,
          lastName: data.lastname,
          country: data.country,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      switch (data.updateUser.__typename) {
        case "UserNotFound":
          toastError(data.updateUser.message);
          break;

        case "UpdateUserError":
          toastError(data.updateUser.message);
          break;

        case "UpdatedUserResponse":
          toastSuccess("Profile updated");
          const user_data = {
            user: { ...data.updateUser },
          };
          auth.updateAuth(user_data);
          break;

        default:
          return null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { register, errors, handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section className="py-6">
      <div className="flex px-10">
        <H3 color="#0C024D">Profile</H3>
      </div>

      <div className="p-10 md:px-10 md:py-14">
        <form
          onSubmit={handleSubmit(onUpdateUser)}
          className="grid grid-cols-2 gap-6 w-2/3"
        >
          <div className="">
            <DarkFormField
              type="text"
              id="firstname"
              name="firstname"
              w="384px"
              ref={register({
                required: "First name is required",
                minLength: {
                  value: 2,
                  message: "Please enter your first name",
                },
              })}
              placeholder="Enter your first name"
              label="First Name"
              className="w-full"
              defaultValue={auth?.user?.firstName}
            />
            <ErrorMessage
              errors={errors}
              name="firstname"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>
          <div>
            <DarkFormField
              type="text"
              id="lastname"
              name="lastname"
              w="384px"
              ref={register({
                required: "Last Name is required",
                minLength: {
                  value: 2,
                  message: "Please enter your last name",
                },
              })}
              placeholder="Enter your last name"
              label="Last Name"
              className="w-full"
              defaultValue={auth?.user?.lastName}
            />
            <ErrorMessage
              errors={errors}
              name="lastname"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>

          <div>
            <DarkFormField
              type="email"
              id="email"
              name="email"
              w="384px"
              ref={register({
                required: "Email is required",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter a valid email.",
                },
              })}
              placeholder="Enter your email addresss"
              label="Email Address"
              className="w-full"
              defaultValue={auth?.user?.email}
              disabled
            />
            <ErrorMessage
              errors={errors}
              name="email"
              as={
                <P
                  fontSize="12px"
                  className="-mt-4 mb-4 text-red-400 text-sm"
                />
              }
            >
              {({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <P key={type}>{message}</P>
                ))
              }
            </ErrorMessage>
          </div>
          {auth?.user?.country && countries && (
            <div>
              <DarkSelectInput
                className="pr-4"
                name="country"
                w="384px"
                id="country"
                type="select"
                ref={register({
                  required: "Country is required.",
                })}
                label="Country"
                defaultValue={auth?.user?.country}
              >
                <option value="" disabled>
                  Select a country
                </option>
                {countries.map((_country, idx) => (
                  <option key={idx} value={_country.code3}>
                    {_country.name}
                  </option>
                ))}
              </DarkSelectInput>
              <ErrorMessage
                errors={errors}
                name="country"
                as={
                  <P
                    fontSize="12px"
                    className="-mt-4 mb-4 text-red-400 text-sm"
                  />
                }
              >
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <P key={type}>{message}</P>
                  ))
                }
              </ErrorMessage>
            </div>
          )}

          <div>
            <PrimaryButton className="" disabled={loading}>
              {loading ? "Updating" : "Save changes"}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ProfileFormPage;
