/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import LoadingBar from "react-top-loading-bar";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { StoreCtx } from "../../store/Store";

import loaderImg from "../../assets/loader.svg";

import { P, H3 } from "../../components/Texts";
import { DashboardHeader } from "../../components/Headers";
import { PublishFooter } from "../../components/Footers";
import { TopicCard, NotificationBar } from "../../components/Cards";
import { toastSuccess } from "../../lib/utils";
import { authContext } from "../../store/AuthStore";
import Loader from "../../components/Loader";

const Main = styled.div`
  max-height: calc(100vh - 147px);
  overflow-y: auto;
`;

const LoaderHolder = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #ffffff99;

  > div {
    background: #ffffff99;
    width: 100%;
    height: 100%;
  }
`;

const UPLOAD_DATA = gql`
  mutation UploadData($data: NewDatapackage) {
    fileUpload(data: $data) {
      id
      name
      topic
    }
  }
`;

const PublishStep = (props) => {
  const loader = useRef(null);
  let history = useHistory();
  const { auth } = useContext(authContext);

  const { changeStep, updateNewData, discardNewData } = props;

  const store = useContext(StoreCtx);
  const { data_visibility } = store;

  const [dataType, setDataType] = useState(null);
  const [data, setData] = useState({});
  const [progress, setProgress] = useState();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const [uploadData, { loading, error, data: result }] =
    useMutation(UPLOAD_DATA);

  useEffect(() => {
    if (store && store.data_format) {
      setData(store);
    }

    if (store && (store.data_format || store.data_visibility)) {
      setDataType(data_visibility);
      setData(store);
    }
  }, [data_visibility, store]);

  const changeAccess = (access) => {
    setDataType(access);
    updateNewData({ data_visibility: access });
  };

  const submitDetails = async () => {
    const file = data.files?.[0].data_url;
    setIsUploadingFile(true);
    // Create Form data object for adding file to request.
    let formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "https://ssn-datalake-staging.herokuapp.com/data/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        },
      );

      if (response.data.data) {
        setIsUploadingFile(false);

        uploadData({
          variables: {
            data: {
              name: data.name,
              title: data.name,
              dataUrl: response.data.data,
              // origin: data.origin,
              // origin_url: data.origin_url,
              description: data.description,
              // data_format: file.format,
              // encoding_type: file.encoding,
              topic: data.topic,
              // subTopic: data.sub_topic,
              access: data.data_visibility,
              country: data.country,
              version: data.version,
            },
          },
        })
          .then(() => {
            toastSuccess("Data submitted successfully");
            discardNewData();
            history.push("/dashboard/data");
            return loader && loader.current?.complete();
          })
          .catch((error) => {
            return loader && loader.current?.complete();
          });
      }
    } catch (error) {
      setIsUploadingFile(false);
      return loader && loader.current?.complete();
    }
  };

  useEffect(() => {
    // console.log("RESULT", result, error);
  }, [result, error, loading]);

  const { handleSubmit } = useForm({
    validateCriteriaMode: "all",
  });

  return (
    <section className="relative">
      <LoadingBar height={2} color="#00AF72" ref={loader} />
      <DashboardHeader title="Create a new data" className="sticky top-0" />

      <Main className="pt-12 relative">
        {error && (
          <NotificationBar
            success={false}
            message="Error uploading data, please try again later"
          />
        )}

        <div className="w-1/2 mx-auto">
          <H3 color="#22202D" className="mb-2 text-center">
            Data Visibility
          </H3>
          <P fontSize="16" color="#716e8b" className="mb-16 text-center">
            Time to put your data out there, how do you want to publish your
            data?
          </P>

          <div className="w-4/5 mx-auto">
            <TopicCard
              title="Public"
              subTitle="Share to other users on the platform"
              className="mb-6"
              border=" solid 2px #E7E5F2"
              selected={dataType === "public" ? true : false}
              onClick={() => changeAccess("public")}
            />

            <TopicCard
              title="Private"
              subTitle="Visible to you alone"
              className="mb-6"
              border=" solid 2px #E7E5F2"
              selected={dataType === "private" ? true : false}
              onClick={() => changeAccess("private")}
            />
          </div>
        </div>
      </Main>

      <PublishFooter
        submitDetails={handleSubmit(submitDetails)}
        dataType={dataType}
        changeStep={(step) => changeStep(step)}
        discard={discardNewData}
      />

      {(isUploadingFile || loading) && (
        <LoaderHolder>
          <div className="flex flex-col justify-center items-center">
            {isUploadingFile ? (
              <>
                <div className="md:w-1/4 mb-4 mx-auto">
                  <Loader w={progress} />
                </div>
                <P color="#0D024E">Uploading your file, please be patient</P>
              </>
            ) : (
              <>
                <img src={loaderImg} alt="Loading" className="mb-6 mx-auto" />
                <P color="#0D024E">Finishing up, almost there</P>
              </>
            )}
          </div>
        </LoaderHolder>
      )}
    </section>
  );
};

export default PublishStep;
